<!-- <div class="row">
    <div class="col-md-12">
        <div class="text-center">
            <div class="one">
                <h1 [ngStyle]="{'color':commonservice.colorfrontblack.value}"><i class="fa fa-building-o"
                        aria-hidden="true"></i> {{'SCHEMES.Title' | translate}} </h1>
            </div>
        </div>
    </div>
</div> -->

    <div class="container-fluid">      
            <div class="col-md-12">
                <mat-card>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label>Filter</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                        </mat-form-field>

                        <div class="mat-elevation-z8" style="overflow: auto">
                            <table mat-table [dataSource]="dataSource" matSort class="restable">
                                <!-- ID Column -->
                                <ng-container matColumnDef="sn">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                        > {{'SCHEMES.Sno' | translate}}</th>
                                    <td mat-cell *matCellDef="let row"
                                    [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" > {{row.sn}} </td>
                                </ng-container>

                                <ng-container matColumnDef="scheme_name">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                        > {{'SCHEMES.SchemeNmEng' | translate}}
                                    </th>
                                    <td mat-cell *matCellDef="let row"
                                    [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                                        {{row.scheme_name}} </td>
                                </ng-container>

                                <ng-container matColumnDef="scheme_namehn">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                        > {{'SCHEMES.SchemeNmHi' | translate}}
                                    </th>
                                    <td mat-cell *matCellDef="let row"
                                    [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                                        {{row.scheme_namehn}} </td>
                                </ng-container>

                                <ng-container matColumnDef="scheme_data">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                        >{{'SCHEMES.AbScheme' | translate}} </th>
                                    <td mat-cell *matCellDef="let row"
                                    [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                                        {{row.scheme_data}} </td>
                                </ng-container>

                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">{{'SCHEMES.NoRecord' | translate}}  "{{input.value}}"</td>
                                </tr>
                            </table>

                            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                        </div>

                    </mat-card-content>


                </mat-card>

            </div>
        
    </div>