import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';

import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-organizationalchart',
  templateUrl: './organizationalchart.component.html',
  styleUrls: ['./organizationalchart.component.scss']
})
export class OrganizationalchartComponent implements OnInit {

  rootUrl = environment.rootUrl;
  
  constructor(private http: HttpClient, private dp: DatePipe,
    public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {
  }

}
