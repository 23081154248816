
<div class="my-3" id="b-homedb">
  <div class="container-fluid">

      <div *ngIf="cms.lang.value=='English'" id="content">
        <div
          id="p_p_id_103_"
          class="portlet-boundary portlet-boundary_103_ portlet-static portlet-static-end"
        >
          <span id="p_103"></span>
          <div class="portlet-body"></div>
        </div>
        <div class="columns-1" id="main-content" role="main">
          <div class="portlet-layout">
            <div class="portlet-column portlet-column-only" id="column-1">
              <div
                class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1"
              >
                <div
                  id="p_p_id_56_INSTANCE_UT5faHJMR8Ot_"
                  class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                >
                  <div class="portlet-body">
                    <section class="portlet" id="portlet_56_INSTANCE_UT5faHJMR8Ot">
                      <header class="portlet-topper" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                        <h2 class="portlet-title">
                          <span tabindex="0" class="portlet-title-text"
                          [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                          >Copyright Policy</span
                          >
                        </h2>
                        <ul
                          class="portlet-topper-toolbar"
                          id="portlet-topper-toolbar_56_INSTANCE_UT5faHJMR8Ot"
                        ></ul>
                      </header>
                      <div class="portlet-content">
                        <div class="portlet-content-container">
                          <div class="portlet-body">
                            <div
                              class="journal-content-article"
                              id="article_10153_10179_12433_6.6"
                            >
                              <div style="margin-left: 5px; margin-right: 5px">
                                <div class="about-ssdg">
                                  <p class="a_heading">
                                    <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"> <b>Introduction </b> </strong>
                                  </p>
                                  <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    The contents of this website may not be reproduced partially or fully, without due permission from State Planning
                                    Commission, Government Of Chhattisgarh. If referred to as a part of another publication, the source must be
                                    appropriately acknowledged. The contents of this website can not be used in any misleading or objectionable context.
                                  </p>

                                  <p class="a_heading">
                                    <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"><b>Terms of Use</b></strong>
                                  </p>
                                  <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  <b>Disclaimer</b>
                                  <br>
                                  This Portal is designed, developed and hosted by Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh. Though all
                                  efforts have been made to ensure the accuracy and currency of the content on this Portal, the same should not be
                                  construed as a statement of law or used for any legal purposes. Users are advised to verify/check any information with
                                  the relevant Government department(s) and/or other source(s), and to obtain any appropriate professional advice before
                                  acting on the information provided in the Portal.
                                  </p>

                                  <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    <b> Privacy Policy </b>
                                    <br>
                                    Rajya Niti Aayog,Chhattisgarh, Government Of Chhattisgarh Portal does not automatically capture any specific personal
                                    information from you,(like name, phone number or e-mail address), that allows us to identify you individually. If this
                                    Portal requests you to provide personal information, you will be informed for the particular purposes for which the
                                    information is gathered and adequate security measures will be taken to protect your personal information. We do not
                                    sell or share any personally identifiable information volunteered on the India Portal site to any third party
                                    (public/private). Any information provided to this Portal will be protected from loss, misuse, unauthorized access or
                                    disclosure, alteration, or destruction.We gather certain information about the User, such as Internet protocol (IP)
                                    addresses, domain name, browser type, operating system, the date and time of the visit and the pages visited. We make no
                                    attempt to link these addresses with the identity of individuals visiting our site unless an attempt to damage the site
                                    has been detected.
                                  </p>

                                 
                                  <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    Linking Policy
                                    <br>
                                    Links to Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh website by other websites
                                    We do not object to you linking directly to the information that is hosted on this Portal and no prior permission is
                                    required for the same. However, we would like you to inform us about any links provided to this Portal so that you can
                                    be informed of any changes or updations therein. Also, we do not permit our pages to be loaded into frames on your site.
                                    The pages belonging to this Portal must load into a newly opened browser window of the User.
                                    Links to external websites/portals
                                    At many places in this Portal, you shall find links to other websites/portals. This links have been placed for your
                                    convenience. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh is not responsible for the contents and reliability
                                    of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its
                                    listing on this Portal should not be assumed as endorsement of any kind. We can not guarantee that these links will work
                                    all the time and we have no control over availability of linked pages.
                                  </p>

                                 
                                  <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    <b>Copyright Policy</b>
                                  </p>
                                  <ul>
                                    <li style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      All copyrights are reserved with the Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh.The material posted on the
                                      website may be reproduced without formal permission for the purposes of non-commercial research, private study, review
                                      and news reporting provided that the material is appropriately attributed. However, the material has to be reproduced
                                      accurately and not to be used in a derogatory manner or in a misleading context.
                                      
                                     
                                    </li>
                                    <li style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      The permissions to reproduce this material shall not extend to any material which is identified as being copyright of a
                                      third party. Authorisation to reproduce such material must be obtained from the copyright holders concerned.
                                    </li>                                   
                                  </ul>

                                  <p class="a_heading">
                                    <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"
                                      >Legal Notice Policy</strong
                                    >
                                  </p>
                                  <p [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  All content of this site is owned or controlled by Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh and is
                                  protected by copyright laws and international treaties. You may download content only for your personal use for
                                  non-commercial purposes but no modification or further reproduction of the content is permitted. The content may
                                  otherwise not be copied or used in any way.
                                  We will use reasonable efforts to include up-to-date and accurate information in this site, but makes no
                                  representations, warranties or assurances as to the accuracy, currency, or completeness of the information provided. We
                                  shall not be liable for any damages or injury resulting from your access to, or inability to access, this site or from
                                  your reliance on any information provided at this site. This site may provide links or references to other sites but we
                                  have no responsibility for the content of such other sites and shall not be liable for any damages or injury arising
                                  from that content. Any links to other sites are provided as merely a convenience to the users of this site.
                                  The trademarks, service marks, trade names, trade dress and products in this site are protected in the United States and
                                  internationally. No use of any of these may be made without the prior, written authorization of the owners of this site,
                                  except to identify the products or services of the company.
                                  Any Personally Identifiable Information in electronic communications to this site is governed by this site's Privacy
                                  Policy. We shall be free to use or copy all other information in any such communications, including any ideas,
                                  inventions, concepts, techniques or know-how disclosed therein, for any purposes. Such purposes may include disclosure
                                  to third parties and/or developing, manufacturing and/or marketing goods or services.
                                  The sender of any communications to this site or otherwise to us shall be responsible for the content and information
                                  contained therein, including its truthfulness and accuracy.
                                  </p>
                                 
                                </div>
                              </div>
                            </div>
                            <div class="entry-links"></div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
      </div>

      <div *ngIf="cms.lang.value=='Hindi'" id="content">
        <div class="columns-1" id="main-content" role="main">
          <div class="portlet-layout">
            <div class="portlet-column portlet-column-only" id="column-1">
              <div
                class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1"
              >
                <div
                  id="p_p_id_56_INSTANCE_UT5faHJMR8Ot_"
                  class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                >
                  <span id="p_56_INSTANCE_UT5faHJMR8Ot"></span>
                  <div class="portlet-body">
                    <section class="portlet" id="portlet_56_INSTANCE_UT5faHJMR8Ot">
                      <header class="portlet-topper" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                        <h2 class="portlet-title">
                          <span tabindex="0" class="portlet-title-text"
                          [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                          > &nbsp; सर्वाधिकार नीति
                          </span>
                        </h2>
                        
                      </header>
                      <div class="portlet-content">
                        <div class="portlet-content-container" style="">
                          <div class="portlet-body">
                            <div
                              class="journal-content-article"
                              id="article_10153_10179_12433_6.6"
                            >
                              <div class="about-ssdg">
                                
                                <p style="text-align: justify">
                                  <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"> <b>परिचय</b></strong>
                                </p>
                                <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                इस वेबसाइट की विषय-वस्तु राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार के यथा-अनुमोदन के बिना आंशिक अथवा पूर्ण रूप से पुन: प्रस्तुतत
                                नहीं की जा सकेगी। यदि किसी अन्ये प्रकाशन के भाग के तौर पर संदर्भ देना हो तो स्रोत को उपयुक्तन ढंग से स्वीकार किया जाना
                                चाहिए। इस वेबसाइट की विषय-वस्तुत को भ्रामक अथवा आपत्तिजनक प्रसंग में प्रयोग नहीं किया जा सकता।
                                </p>
                                
                                <p style="text-align: justify">
                                  <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"><b>उपयोग की शर्तें</b> </strong
                                  ><strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"><b>खंडन</b></strong>
                                </p>
                                <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                  &nbsp;यह पोर्टल राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार द्वारा विकसित और होस्ट किया गया है। यद्यपि इस पोर्टल पर सामग्री की सटीकता और
                                  मुद्रा सुनिश्चित करने के लिए सभी प्रयास किए गए हैं, वैसे ही इसे कानून के एक बयान के रूप में नहीं समझा जाना चाहिए या किसी
                                  कानूनी प्रयोजनों के लिए उपयोग नहीं किया जाना चाहिए। उपयोगकर्ता को सलाह दी जाती है कि वे संबंधित सरकारी विभागों और / या
                                  अन्य स्रोतों के साथ किसी भी जानकारी की जांच करें और पोर्टल में दी गई जानकारी पर कार्य करने से पहले किसी भी उचित पेशेवर
                                  सलाह प्राप्त करें।
                                  &nbsp;
                                </p>

                                <p style="text-align: justify">
                                  <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"><b>गोपनीयता नीति</b> </strong>
                                </p>
                                
                                
                                <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार पोर्टल आपसे कोई विशिष्ट व्यक्तिगत जानकारी (जैसे नाम, फोन नंबर या ई-मेल पता) स्वचालित
                                  रूप से कैप्चर नहीं करता है, जो हमें आपको अलग-अलग पहचानने की अनुमति देता है यदि यह पोर्टल आपको व्यक्तिगत जानकारी प्रदान
                                  करने का अनुरोध करता है, तो आपको उन विशेष प्रयोजनों के लिए सूचित किया जाएगा जिनके लिए जानकारी एकत्र की जाती है और आपकी
                                  व्यक्तिगत जानकारी की सुरक्षा के लिए पर्याप्त सुरक्षा उपाय किए जाएंगे। हम किसी भी तीसरे पक्ष (सार्वजनिक / निजी) को भारत
                                  पोर्टल साइट पर स्वेच्छा से व्यक्तिगत रूप से पहचाने जाने योग्य कोई जानकारी नहीं बेचते या साझा नहीं करते हैं। इस पोर्टल पर
                                  दी गई कोई भी जानकारी हानि, दुरुपयोग, अनधिकृत पहुंच या प्रकटीकरण, परिवर्तन या विनाश से सुरक्षित होगी। हम उपयोगकर्ता के
                                  बारे में कुछ जानकारी, जैसे इंटरनेट प्रोटोकॉल (आईपी) पते, डोमेन नाम, ब्राउज़र प्रकार, ऑपरेटिंग सिस्टम, यात्रा की तारीख और
                                  समय और पृष्ठों का दौरा किया। हम इन पतों को हमारी साइट पर आने वाले व्यक्तियों की पहचान के साथ जोड़ने का प्रयास नहीं करते
                                  हैं, जब तक कि साइट को नुकसान पहुंचाने का कोई प्रयास नहीं किया गया है।
                                </p>
                                
                                <p style="text-align: justify">
                                  <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"><b>लिंकिंग नीति</b>  </strong>
                                </p>
                                
                                <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  अन्य वेबसाइट्स द्वारा राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार की वेबसाइट पर लिंक्स
                                  हम आपको इस पोर्टल पर होस्ट की जाने वाली सूचना के सीधे लिंक करने पर आपत्ति नहीं करते हैं और इसके लिए कोई पूर्व अनुमति की
                                  आवश्यकता नहीं है। हालांकि, हम चाहते हैं कि आप इस पोर्टल पर दिए गए किसी भी लिंक के बारे में हमें सूचित करें ताकि आपको
                                  उसमें कोई भी बदलाव या अपडेट के बारे में सूचित किया जा सके। साथ ही, हम आपकी साइट पर हमारे पृष्ठों को फ़्रेम में लोड करने
                                  की अनुमति नहीं देते। इस पोर्टल से संबंधित पृष्ठ उपयोगकर्ता के एक नए खुले ब्राउज़र विंडो में लोड होने चाहिए।
                                  बाहरी वेबसाइटों / पोर्टल्स के लिए लिंक
                                  इस पोर्टल में कई स्थानों पर, आपको अन्य वेबसाइटों / पोर्टल्स के लिंक मिलेंगे। यह लिंक आपकी सुविधा के लिए रखा गया है।
                                  राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार लिंक्ड वेबसाइटों की सामग्री और विश्वसनीयता के लिए ज़िम्मेदार नहीं है और जरूरी नहीं कि
                                  उन में व्यक्त विचारों का समर्थन करता है। इस पोर्टल पर लिंक की उपस्थिति या उसकी प्रविष्टि को किसी भी प्रकार के समर्थन के
                                  रूप में नहीं माना जाना चाहिए। हम यह गारंटी नहीं दे सकते कि ये लिंक सभी समय काम करेंगे और लिंक किए गए पृष्ठों की उपलब्धता
                                  पर हमारे पास कोई नियंत्रण नहीं है।
                                </p>                              
                               
                                <p style="text-align: justify">
                                  <strong
                                  [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    <b>सर्वाधिकार नीति </b>&nbsp;</strong
                                  >
                                </p>
                                <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                  सभी कॉपीराइट राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार के साथ आरक्षित हैं। वेबसाइट पर पोस्ट की गई सामग्री को गैर-वाणिज्यिक
                                  अनुसंधान, निजी अध्ययन, समीक्षा और समाचार रिपोर्टिंग के प्रयोजनों के लिए बिना औपचारिक अनुमति के पुनर्मुद्रण किया जा सकता
                                  है, बशर्ते सामग्री का उचित रूप से श्रेय दिया गया हो। हालांकि, सामग्री का पुन: सटीक रूप से पुन: प्रस्तुत किया जाना चाहिए
                                  और किसी अपमानजनक तरीके से या भ्रामक संदर्भ में उपयोग नहीं किया जाना चाहिए
                                  <br>
                                  इस सामग्री को पुन: पेश करने की अनुमति किसी भी सामग्री तक नहीं बढ़ेगी जो कि किसी तीसरे पक्ष के कॉपीराइट के रूप में पहचानी
                                  गई है ऐसी सामग्री को पुन: उत्पन्न करने के लिए प्राधिकरण संबंधित कॉपीराइट धारकों से प्राप्त किया जाना चाहिए।
                                </p>
                                
                              <p style="text-align: justify">
                                <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                 <b> कानूनी नोटिस नीति </b>&nbsp;</strong>
                              </p>
                              <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                              इस साइट की सभी सामग्री को राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार द्वारा स्वामित्व या नियंत्रित किया गया है और कॉपीराइट
                              कानूनों और अंतर्राष्ट्रीय संधियों द्वारा संरक्षित है। आप केवल गैर-व्यावसायिक उद्देश्यों के लिए अपने व्यक्तिगत उपयोग के
                              लिए सामग्री डाउनलोड कर सकते हैं लेकिन कोई भी संशोधन या सामग्री के आगे प्रजनन की अनुमति नहीं है। सामग्री को अन्यथा किसी
                              भी तरह से कॉपी या उपयोग नहीं किया जा सकता है।<br>
                              हम इस साइट में अप-टू-डेट और सटीक जानकारी को शामिल करने के लिए उचित प्रयासों का उपयोग करेंगे, लेकिन प्रदान की गई जानकारी
                              की सटीकता, मुद्रा या पूर्णता के रूप में कोई अभ्यावेदन, वारंटी या आश्वासन नहीं बनाते हैं। हम इस साइट पर उपलब्ध कराई गई
                              किसी भी जानकारी पर पहुंचने में असमर्थता, इस साइट या आपके रिलायंस से आपकी क्षति या क्षति के लिए उत्तरदायी नहीं होंगे। यह
                              साइट अन्य साइटों के लिंक या संदर्भ प्रदान कर सकती है लेकिन ऐसी अन्य साइटों की सामग्री के लिए हमें कोई ज़िम्मेदारी नहीं
                              है और उस सामग्री से होने वाली किसी भी क्षति या चोट के लिए उत्तरदायी नहीं होगा। अन्य साइट्स के लिए कोई भी लिंक इस साइट के
                              उपयोगकर्ताओं के लिए केवल एक सुविधा के रूप में प्रदान किया जाता है। इस साइट में ट्रेडमार्क, सर्विस मार्क्स, ट्रेड नेम,
                              ट्रेड ड्रेस और उत्पाद संयुक्त राज्य अमेरिका और अंतरराष्ट्रीय स्तर पर सुरक्षित हैं। कंपनी के उत्पादों या सेवाओं की पहचान
                              के अलावा, इनमें से किसी भी का उपयोग इस साइट के मालिकों के पूर्व, लिखित प्राधिकरण के बिना किया जा सकता है।
                              इस साइट पर इलेक्ट्रॉनिक संचार में कोई भी व्यक्तिगत रूप से पहचान योग्य जानकारी इस साइट की गोपनीयता नीति द्वारा नियंत्रित
                              है। किसी भी तरह के संचार, किसी भी विचार, आविष्कार, अवधारणाओं, तकनीकों या उसमें बताए गए ज्ञान सहित, किसी भी प्रयोजनों के
                              लिए हम सभी अन्य जानकारी का उपयोग या कॉपी करने के लिए स्वतंत्र होंगे। इस तरह के प्रयोजनों में तीसरे पक्ष के प्रकटीकरण और
                              / या विकास, विनिर्माण और / या माल या सेवाओं का विपणन शामिल हो सकते हैं।<br>
                              इस साइट पर किसी भी संचार का प्रेषक या अन्यथा हमारे लिए उसमें सामग्री और जानकारी के लिए ज़िम्मेदार होगी, जिसमें इसकी
                              सच्चाई और सटीकता शामिल है।
                              </p>
                               
                               
                              </div>
                            </div>
                            <div class="entry-links"></div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
      </div>
  </div>
</div>