import { BreadcrumbComponent } from './layout/breadcrumb/breadcrumb.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SafeUrlPipe } from './safe-url.pipe';
import { ErrorInterceptor } from './interceptors/http.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HomeComponent } from './home/home.component';
import { Spinner } from './interceptors/spinner.interceptor';
import { FullComponent } from './layout/full/full.component';
import { MenuComponent } from './layout/menu/menu.component';
import { MaterialModule } from './material-module';
import { SpinnerService } from './services/spinner.service';
import { SpinnerComponent } from './spinner.component';
import { JwtModule } from '@auth0/angular-jwt';
import { AboutusComponent } from './aboutus/aboutus.component';
import { ProfileComponent } from './shared/profile/profile.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { NoticeboardComponent } from './noticeboard/noticeboard.component';
import { DatePipe, HashLocationStrategy, LocationStrategy } from '@angular/common';
import { CensusComponent } from './census/census.component';
import { SchemeComponent } from './scheme/scheme.component';
import { ImpinfoComponent } from './impinfo/impinfo.component';
import { SchemedetailsComponent } from './schemedetails/schemedetails.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GalleryComponent } from './gallery/gallery.component';
import { ReportsComponent } from './reports/reports.component';
import { FeedbackComponent } from './feedback/feedback.component';
import { RecruitmentnoticeComponent } from './recruitmentnotice/recruitmentnotice.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AboutPortalComponent } from './about-portal/about-portal.component';
import { FaqsComponent } from './faqs/faqs.component';
import { ContactusComponent } from './contactus/contactus.component';
import { ScreenreaderComponent } from './screenreader/screenreader.component';
import { AcessiblitySttmntComponent } from './acessiblity-sttmnt/acessiblity-sttmnt.component';
import { SitemapComponent } from './sitemap/sitemap.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { HttpClientModule } from '@angular/common/http';
import { TeamspcComponent } from './teamspc/teamspc.component';
import { WhoswhoComponent } from './whoswho/whoswho.component';
import { OrganizationalchartComponent } from './organizationalchart/organizationalchart.component';
import { TaskforceComponent } from './taskforce/taskforce.component';
import { OtheractivitiesComponent } from './otheractivities/otheractivities.component';
import { PlansComponent } from './plans/plans.component';
import { CgmapComponent } from './cgmap/cgmap.component';
import { AchivementsComponent } from './achivements/achivements.component';
import { SdgnoticeboardComponent } from './sdgnoticeboard/sdgnoticeboard.component';
import { MounoticeboardComponent } from './mounoticeboard/mounoticeboard.component';
import { ResearchnoticeboardComponent } from './researchnoticeboard/researchnoticeboard.component';
import { TendernoticeboardComponent } from './tendernoticeboard/tendernoticeboard.component';
import { SdgComponent } from './sdg/sdg.component';
import { BestpracticeComponent } from './bestpractice/bestpractice.component';
import { ViksitcgComponent } from './viksitcg/viksitcg.component';

export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    AppComponent,
    SpinnerComponent,
    HomeComponent,
    FullComponent,
    MenuComponent,
    AboutusComponent,
    ProfileComponent,
    NoticeboardComponent,
    FooterComponent,
    CensusComponent,
    BreadcrumbComponent,
    SchemeComponent,
    ImpinfoComponent,
    SchemedetailsComponent,
    GalleryComponent,
    ReportsComponent,
    FeedbackComponent,
    SafeUrlPipe,
    RecruitmentnoticeComponent,
    AboutPortalComponent,
    FaqsComponent,
    ContactusComponent,
    ScreenreaderComponent,
    AcessiblitySttmntComponent,
    SitemapComponent,
    PrivacyPolicyComponent,
    TeamspcComponent,
    WhoswhoComponent,
    OrganizationalchartComponent,
    TaskforceComponent,
    OtheractivitiesComponent,
    PlansComponent,
    CgmapComponent,
    AchivementsComponent,
    SdgnoticeboardComponent,
    MounoticeboardComponent,
    ResearchnoticeboardComponent,
    TendernoticeboardComponent,
    SdgComponent,
    BestpracticeComponent,
    ViksitcgComponent
    
  ],
  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    CarouselModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    ImageCropperModule,
    HttpClientModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    JwtModule.forRoot({
      config: {
        tokenGetter: function tokenGetter() {
          return localStorage.getItem('token');
        },
        // whitelistedDomains: ['localhost:3000'],
        // blacklistedRoutes: ['http://localhost:3000/auth/login']
      }
    }),
  ],
  providers: [
    DatePipe,
    SpinnerService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Spinner,
      multi: true
    }, {
      provide: LocationStrategy,
      useClass: HashLocationStrategy
    }, ,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

