import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-whoswho',
  templateUrl: './whoswho.component.html',
  styleUrls: ['./whoswho.component.scss']
})
export class WhoswhoComponent implements OnInit {

  public temp: any = [];
  public data: any = [];
  public teamdesc: any = [];
  rootUrl = environment.rootUrl;

  constructor(private http: HttpClient, private dp: DatePipe, public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {
    this.getSPCTeam();
  }

  getSPCTeam() {
    this.commonservice.getFunction('whoswho').subscribe(res => {
      this.data = res;
      console.log("hellooo", this.data);
    });
  };

}
