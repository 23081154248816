import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-sdg',
  templateUrl: './sdg.component.html',
  styleUrls: ['./sdg.component.scss']
})
export class SdgComponent implements OnInit {

  public id: any;
  public sdgnoticeboard: FormGroup; //add  FormGroup 
  constructor(private fb: FormBuilder, public commonservice: CommonService,
    public cms: CommonService, private route: ActivatedRoute) {
    this.sdgnoticeboard = this.fb.group({ //definition to cons
      document_type: ['']
    });
  }


  ngOnInit(): void {

    window.open('https://sdgspc.cg.gov.in/#/home', '_blank');

    // this.id = this.route.snapshot.paramMap.get('id');
    // //window.location.href = 'https://sdgspc.cg.gov.in/?lang=en';
    // if (this.id == "1")
    // {
    //   console.log(this.id);
    //   window.open('https://sdgspc.cg.gov.in/?lang=en', '_blank');
    // }
    // if (this.id == "2") 
    // {
    //   window.open('https://sdgspc.cg.gov.in/bestpractice/#/home', '_blank');
    // }
    // // if (this.id == "3") 
    // // {
    // //   window.open('https://sdgspc.cg.gov.in/viksitcg/#/report', '_blank');
    // // }
   
  }

}
