
        <div class="my-5" id="b-homedb">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-md-8">
                        <div class="card h-100">
                            <div class="card-header text-center" [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                <img src="../../assets/images/logo/spc.png" alt="" style="height: 25px;width: 25px;">
                                {{'SPCDETAILS.Title' |translate}}</div>
                            <div class="card-body">
                                <img src="assets/images/big/SPC.jpg" alt="" style="width: 100%;height:250px;"> &nbsp;
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.Para1' |
                                    translate}}
                                </p>
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.Para2' |
                                    translate}}
                                </p>
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.Para3' |
                                    translate}}
                                </p>
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.Para4' |
                                    translate}}
                                </p>
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.Para5' |
                                    translate}}
                                </p>
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.Para6' |
                                    translate}}
                                </p>                               
                                <table id="table1">
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle1'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc1'|translate}}</td>                            
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle2'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc2'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle3'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc3'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle4'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc4'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle5'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc5'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle6'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc6'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle7'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc7'|translate}}</td>
                                    </tr>
                                    <tr>
                                        <th>{{'SPCDETAILS.TableTitle8'|translate}}</th>
                                        <td>{{'SPCDETAILS.TableDesc8'|translate}}</td>
                                    </tr>
                                </table>

                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
                                    {{'SPCDETAILS.SPCFooter' |
                                    translate}}
                                </p>
                            </div>
                        
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card">
                            <div class="card-header text-center" [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                <img src="../../assets/images/icons/cg.png" alt="" style="height: 25px;width: 25px;">
                                {{'MAP.ChhattisgarhMap' | translate}}</div>
                            <div class="card-body">                                
                                <b> <i class="fa fa-hand-o-right" aria-hidden="true"></i> {{'ABOUTSPC.AboutCGMap' |translate}} </b>
                                <br>
                                <br>
                                <!-- <img src="../../assets/images/statesymbol/cgmap.png" alt="" style="width: 100%;height: 550px;">
                                <iframe width="600" height="450" style="border:0" loading="lazy" allowfullscreen
                                        src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJrYt6xhYfJjoRlZ0X-xL0I1w&key=AIzaSyBDrzQ0rmz6lhDRFR-BasubOFs8kugnvGo"></iframe> -->
                                <app-cgmap></app-cgmap>
                              
                            </div>
                        </div>
                    </div>            
                </div>
            </div>
        </div>

        <div class="my-5" id="b-homedb">
            <div class="container-fluid">
                <div class="row">                    
                    <div class="col-md-6">
                        <div class="card h-100">
                            <div class="card-header text-center"
                                [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                {{'SPCDETAILS.SPCRoleTitle' |translate}}</div>
                            <div class="card-body">
                                <ul>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc1' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc2' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc3' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc4' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc5' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc6' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc7' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCRoleDesc8' |translate}} </p>
                                    </li>
                                </ul>
                            </div>
                    
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="card h-100">
                            <div class="card-header text-center"
                                [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                {{'SPCDETAILS.SPCTaskForceTitle' |translate}}</div>
                            <div class="card-body">
                                <ul>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce1' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce2' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce3' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce4' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce5' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce6' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce7' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce8' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce9' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce10' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce11' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce12' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce13' |translate}} </p>
                                    </li>
                                    <li style="list-style-type:square">
                                        <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'SPCDETAILS.SPCTaskForce14' |translate}} </p>
                                    </li>
                                </ul>
                            </div>
                    
                        </div>
                    </div>
                </div>
            </div>
        </div>


