<div class="my-3" id="b-homedb">
  <div class="container-fluid">
            <div  *ngIf="cms.lang.value == 'English'" id="content">
              <div class="columns-1" id="main-content" role="main">
                <div class="portlet-layout">
                  <div class="portlet-column portlet-column-only" id="column-1">
                    <div
                      class="portlet-dropzone portlet-column-content portlet-column-content-only"
                      id="layout-column_column-1"
                    >
                      <div
                        id="p_p_id_56_INSTANCE_VqYfvRyD0gCE_"
                        class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                      >
                        <span id="p_56_INSTANCE_VqYfvRyD0gCE"></span>
                        <div class="portlet-body">
                          <section class="portlet" id="portlet_56_INSTANCE_VqYfvRyD0gCE">
                            <header
                              class="portlet-topper"
                              [ngStyle]="{
                                'background-color': cms.colorfront.value,
                                color: cms.colorcode.value,
                                'font-size': cms.fontsize.value + 'px'
                              }"
                            >
                              <h2 class="portlet-title">
                                <span
                                  tabindex="0"
                                  class="portlet-title-text"
                                  [ngStyle]="{
                                    color: cms.colorcode.value,
                                    'font-size': cms.fontsize.value + 'px'
                                  }"
                                  >Privacy Policy</span
                                >
                              </h2>
                            </header>
                            <div class="portlet-content">
                              <div class="portlet-content-container" style="">
                                <div class="portlet-body">
                                  <div
                                    class="journal-content-article"
                                    id="article_10153_10179_12471_5.4"
                                  >
                                    <div class="disclaim">
                                      <h2 class="header_disclaim">
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Privacy Policy</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p
                                          style="text-align: justify"
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                        >
                                          As a general rule, this website does not collect Personal Information about you when you visit the site. You can
                                          generally visit the site without revealing Personal Information, unless you choose to provide such information.
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Site Visit Data:</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p
                                          style="text-align: justify"
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                        >
                                          This website records your visit and logs the following information for statistical purposes your server's address; the
                                          name of the top-level domain from which you access the Internet (for example, .gov, .com, .in, etc.); the type of
                                          browser you use; the date and time you access the site; the pages you have accessed and the documents downloaded and the
                                          previous Internet address from which you linked directly to the site. <br>
                                          We will not identify users or their browsing activities, except when a law enforcement agency may exercise a warrant to
                                          inspect the service provider's logs.
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Cookies:</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify">
                                          <strong
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                            }"
                                            >A cookie is a piece of software code that an internet web site sends to your browser when you access information at that
                                            site. This site does not use cookies.
                                          </strong>
                                        </p>                           
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Email Management:</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p
                                          style="text-align: justify"
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                        >
                                          Your email address will only be recorded if you choose to send a message. It will only be used for the purpose for which
                                          you have provided it and will not be added to a mailing list. Your email address will not be used for any other purpose,
                                          and will not be disclosed, without your consent.
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Collection of Personal Information:</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p
                                          style="text-align: justify"
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                        >
                                        If you are asked for any other Personal Information you will be informed how it will be used if you choose to give it.
                                        If at any time you believe the principles referred to in this privacy statement have not been followed, or have any
                                        other comments on these principles, please notify the webmaster through the contact us page.
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                          >Note:</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p
                                          style="text-align: justify"
                                          [ngStyle]="{
                                            color: cms.colorfront.value,
                                            'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                          }"
                                        >
                                          The use of the term "Personal Information" in this privacy statement refers to any information from which your identity
                                          is apparent or can be reasonably ascertained.
                                        </p>
                                      </div>


                                      <h2 class="header_disclaim" style="text-align: justify">
                                        <span [ngStyle]="{
                                                                                  color: cms.colorfront.value,
                                                                                  'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                }">Disclaimer:</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{
                                                                                  color: cms.colorfront.value,
                                                                                  'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                }">
                                          This Website is maintained by Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh.
                                        </p>

                                        <p style="text-align: justify" [ngStyle]="{
                                                                                                                          color: cms.colorfront.value,
                                                                                                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                        }">
                                          The contents of this website are for information purposes only, enabling the public at large to have a quick and an easy
                                          access to information and do not have any legal sanctity. Though every effort is made to provide accurate and updated
                                          information, it is likely that the some details such as telephone numbers, names of the officer holding a post, etc may
                                          have changed prior to their update in the website. Hence, we do not assume any legal liability on the completeness,
                                          accuracy or usefulness of the contents provided in this website.
                                        </p>

                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                  color: cms.colorfront.value,
                                                                                                                                                                  'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                }">
                                          The links are provided to other external sites in some web pages/documents. We do not take responsibility for the
                                          accuracy of the contents in those sites. The hyperlink given to external sites do not constitute an endorsement of
                                          information, products or services offered by these sites.
                                        </p>

                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                                                          color: cms.colorfront.value,
                                                                                                                                                                                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                                                        }">
                                          Despite our best efforts, we do not guarantee that the documents in this site are free from infection by computer
                                          viruses etc.
                                        </p>

                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                                                                                                  color: cms.colorfront.value,
                                                                                                                                                                                                                                                  'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                                                                                                }">
                                          We welcome your suggestions to improve this website and request that error(if any) may kindly be brought to our notice.
                                        </p>

                                      </div>


                                      <h2 class="header_disclaim">
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">Hyperlink Policy
                                        </span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                        Links to External Websites/Portals
                                        <br>
                                        At many places in this website, you shall find links to other websites/portals. This links have been placed for your
                                        convenience. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh is not responsible for the contents and reliability
                                        of the linked websites and does not necessarily endorse the views expressed in them. Mere presence of the link or its
                                        listing on this website should not be assumed as endorsement of any kind. We cannot guarantee that these links will work
                                        all the time and we have no control over availability of linked pages.
                                        </p>
                                      </div>

                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          Links to Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh Website by other Websites/Portals
                                          <br>
                                          Prior permission is required before hyperlink are directed from any website/portal to this site. Permission for the
                                          same, stating the nature of the content on the pages from where the link has to be given and the exact language of the
                                          hyperlink should be obtained by sending a request to dk[dot]masta[at]gov[dot]in
                                        </p>
                                      </div>
                                    
                                    </div>
                                  </div>
                                  <div class="entry-links"></div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
            </div>

            <div  *ngIf="cms.lang.value == 'Hindi'" id="content">
              <div class="columns-1" id="main-content" role="main">
                <div class="portlet-layout">
                  <div class="portlet-column portlet-column-only" id="column-1">
                    <div
                      class="portlet-dropzone portlet-column-content portlet-column-content-only"
                      id="layout-column_column-1"
                    >
                      <div
                        id="p_p_id_56_INSTANCE_VqYfvRyD0gCE_"
                        class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                      >
                        <span id="p_56_INSTANCE_VqYfvRyD0gCE"></span>
                        <div class="portlet-body">
                          <section class="portlet" id="portlet_56_INSTANCE_VqYfvRyD0gCE">
                            <header class="portlet-topper" [ngStyle]="{
                              'background-color': cms.colorfront.value,
                              color: cms.colorcode.value,
                              'font-size': cms.fontsize.value + 'px'
                            }">
                              <h2 class="portlet-title">
                                <span tabindex="0" class="portlet-title-text" [ngStyle]="{
                                  'color': cms.colorcode.value,                     
                                  'font-size': cms.fontsize.value + 'px'
                                }"
                                  >गोपनीयता नीति</span
                                >
                              </h2>
                              <ul
                                class="portlet-topper-toolbar"
                                id="portlet-topper-toolbar_56_INSTANCE_VqYfvRyD0gCE"
                              ></ul>
                            </header>
                            <div class="portlet-content">
                              <div class="portlet-content-container" style="">
                                <div class="portlet-body">
                                  <div
                                    class="journal-content-article"
                                    id="article_10153_10179_12471_5.4"
                                  >
                                    <div class="disclaim">
                                      <h2 class="header_disclaim">
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">दावा त्यागकर्ता एवं नीतिया </span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          एक सामान्य नियम के तौर पर जब आप वेबसाइट को खोलते हैं तो यह आपकी व्यक्तिगत जानकारी का संग्रह नहीं करती है। आप व्‍यक्तिगत
                                          सूचना दिये बिना भी सामान्यन तौर पर वेबसाइट को देख सकते हैं, जब तक कि आप ऐसी सूचनाएं न देना चाहें।
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">साइट विजिट डाटा:</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          यह वेबसाइट आपकी विजिट को रिकार्ड करती है तथा सांख्यिकीय प्रयोजनार्थ आपके सर्वर के पते पर निम्न लिखित सूचनाएं लॉग करती
                                          है; आपके द्वारा इंटरनेट की सुलभता के उच्चत स्तिरीय क्षेत्र का नाम (उदाहरण के लिए – .gov, .com, .in इत्याधदि), आपके
                                          द्वारा उपयोग किये गये ब्राउजर का प्रकार; आपके द्वारा साइट देखे जाने का समय और तारीख, आपके द्वारा देखे गए पृष्ठ और
                                          डाऊनलोड किये गये दस्ताटवेज और पूर्ववर्ती इंटरनेट पता जिससे आपने सीधा साइट का लिंक प्राप्त किया।
                                          <br>
                                          हम उपभोक्तागओं अथवा उनके ब्राउजिंग कार्यकलापों की तब तक पहचान नहीं करेंगे जब तक कि कानून लागू करने वाली एजेंसी सेवा
                                          प्रदाता लॉग्स की जांच करने का अधिपत्र नहीं देती है।
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">अतिशय युक्त नीति</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify">
                                          <strong [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}"
                                            >कूकिज:-</strong
                                          >
                                        </p>
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          कुकी सॉफ्टवेयर कोड का वह भाग है जो इंटरनेट वेबसाइट आपके ब्राउजर को तब भेजती है जब आप उस साइट से सूचना प्राप्तै करते हो।
                                          यह साइट कुकिज का प्रयोग नहीं करती है।
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">ई-मेल प्रबंधन:</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                        आपके ई-मेल पते को केवल तभी रिकार्ड किया जाएगा जब आप संदेश भेजने का विकल्पह चुनते हैं। इसका प्रयोग केवल उसी प्रयोजनार्थ
                                        किया जाएगा जिसके लिये आपने यह प्रदान किया है और इसे मेल सूची में शामिल नहीं किया जाएगा। आपके ई-मेल पते का किसी अन्य
                                        प्रयोजनार्थ प्रयोग नहीं किया जाएगा और आपकी सहमति के बिना इसे प्रकट नहीं किया जाएगा।
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}"
                                          >व्याक्तिगत सूचना का एकत्रण: -</span
                                        >
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                        यदि आपसे कोई व्‍यक्तिगत सूचना मांगी जाती है तो आपको यह बताया जाएगा कि आपके द्वारा यह प्रदान किये जाने पर इसका प्रयोग किस
                                        प्रकार किया जाएगा। यदि किसी समय आपको यह महसूस होता है कि इस गोपनीयता विवरण में दिये गये सिद्धांतों की अनुपालना नहीं की
                                        गई है अथवा इन सिद्धांतों पर आपको कोई और टिप्‍पणी करनी है तो कृपया हमसे सम्‍पर्क करें पृष्‍ठ के माध्‍यम से वेब मास्‍टर को
                                        अधिसूचित करें।
                                        </p>
                                      </div>
                                      <h2
                                        class="header_disclaim"
                                        style="text-align: justify"
                                      >
                                        <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">नोट: -</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          इस गोपनीयता विवरण में ‘‘व्यतक्तिगत सूचना, शब्दय का उपयोग किसी भी ऐसी सूचना को दर्शाता है जिससे आपकी पहचान उजागर होती है
                                          अथवा युक्तिसंगत ढंग से उसका निर्धारण किया जा सके।
                                        </p>
                                      </div>  
                                      
                                      
                                      <h2 class="header_disclaim" style="text-align: justify">
                                        <span [ngStyle]="{
                                                                                                                        color: cms.colorfront.value,
                                                                                                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                      }">खंडन</span>
                                      </h2>
                                      <div class="content">
                                        <p style="text-align: justify" [ngStyle]="{
                                                                                                                        color: cms.colorfront.value,
                                                                                                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                      }">
                                          यह वेबसाइट छत्तीसगढ़ सरकार के राज्य नीति आयोग द्वारा संचालित है।
                                        </p>
                                      
                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                color: cms.colorfront.value,
                                                                                                                                                                'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                              }">
                                          इस वेबसाइट की विषय-वस्तुा केवल सूचना प्रयोजनार्थ है ताकि आम लोगों को व्यापपक तौर पर सहजता एवं सुलभता के साथ जानकारी
                                          प्राप्ता हो सके और उसकी कोई वैधानिक शुचिता न हो। हालांकि सटीक एवं अद्यतन जानकारी प्रदान करने का पूरा प्रयास किया गया है
                                          फिर भी इस बात की संभावना है कि कुछ जानकारियां जैसे टेलीफोन नं., फोन नं., किसी पद को धारित करने वाले अधिकारी का नाम
                                          इत्या,दि वेबसाइट को अद्यतन करने से पहले परिवर्तित हो गया हो। इसलिए, हम इस वेबसाइट में प्रदान की गई विषय-वस्तुस की
                                          पूर्णता, सटीकता अथवा उपयोगिता की कोई वैधानिक देयता का दावा नहीं करते हैं।
                                        </p>
                                      
                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                                                        color: cms.colorfront.value,
                                                                                                                                                                                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                                                      }">
                                          कुछ वेब पृष्ठों/दस्तारवेजों में साइट के बाहर के लिंक भी प्रदान किये गये हैं। हम उन साइटों में प्रदान की गई विषय-वस्तुह
                                          की सटीकता का कोई उत्तलरदायित्वद नहीं लेते हैं। बाह्य साइटों के लिए दिये गये हाइपर लिंक उन साइटों द्वारा प्रदान की जा रही
                                          सेवाओं अथवा उत्पाोदों, सूचना इत्यासदि का कोई समर्थन नहीं करते हैं।
                                        </p>
                                      
                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                                                                                                color: cms.colorfront.value,
                                                                                                                                                                                                                                                'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                                                                                              }">
                                        हमारे श्रेष्ठत प्रयासों के बावजूद भी हम इस बात की गारंटी नहीं देते हैं कि इस साइट में प्रदान किये गये दस्तािवेज कम्यूक
                                        टर वायरस इत्यानदि के संक्रमण से मुक्तद हैं।
                                        </p>
                                      
                                        <p style="text-align: justify"
                                          [ngStyle]="{
                                                                                                                                                                                                                                                                                        color: cms.colorfront.value,
                                                                                                                                                                                                                                                                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                                                                                                                                                                                                                                                                      }">
                                          हम इस साइट को और उन्नित बनाने के लिए आपके सुझावों का स्वागत करते हैं और आपसे अनुरोध करते हैं कि कोई भी त्रुटि (यदि कोई
                                          हो) हमारे ध्याऔन में लाएं।
                                        </p>
                                      
                                      </div>

                                        <h2 class="header_disclaim">
                                          <span [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">हाइपरलिंक नीति
                                          </span>
                                        </h2>
                                        <div class="content">
                                          <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                            बाहरी वेबसाइट/पोर्टल का लिंक
                                            <br>
                                            इस वेबसाइट के कई स्थानों पर आपको अन्य वेबसाइटों / पोर्टल्स के लिंक मिलेंगे। यह लिंक आपकी सुविधा के लिए रखा गया है। राज्य
                                            योजना आयोग, छत्तीसगढ़ सरकार लिंक की गई वेबसाइटों की सामग्री और विश्वसनीयता के लिए ज़िम्मेदार नहीं है और जरूरी नहीं कि उन
                                            में व्यक्त विचारों का समर्थन करता है। इस वेबसाइट पर लिंक या इसकी प्रविष्टि की उपस्थिति किसी भी तरह के समर्थन के रूप में
                                            नहीं मानी जानी चाहिए। हम यह गारंटी नहीं दे सकते कि ये लिंक सभी समय काम करेंगे और लिंक किए गए पृष्ठों की उपलब्धता पर
                                            हमारे पास कोई नियंत्रण नहीं है।
                                          </p>
                                        </div>
                                        
                                        <div class="content">
                                          <p style="text-align: justify" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}">
                                          अन्यी वेबसाइटों/पोर्टलों द्वारा राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार की वेबसाइटसे लिंक
                                          <br>                                          
                                          इस साइट पर किसी वेबसाइट/पोर्टल से कोई हाइपरलिंक लेने से पहले पूर्व अनुमति लेनी होगी। इसकी अनुमति लेने के लिए
                                          dk[dot]masta[at]gov[dot]in को पृष्ठोंप को जहां से लिंक किया गया है और हाइपरलिंक की उचित भाषा की विषय-वस्तुल की प्रकृति
                                          को दर्शाना होगा।
                                          </p>
                                        </div>


                                    </div>
                                  </div>
                                  <div class="entry-links"></div>
                                </div>
                              </div>
                            </div>
                          </section>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
            </div>
  </div>
</div>
