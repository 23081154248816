import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-mounoticeboard',
  templateUrl: './mounoticeboard.component.html',
  styleUrls: ['./mounoticeboard.component.scss']
})
export class MounoticeboardComponent implements OnInit {

  [x: string]: any;
  rootUrl = environment.rootUrl;
  public displayedColumns: string[] = ['sn', 'linkname', 'menu_tab_linkurl'];
  public dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  public data: any = [];
  public dept: any = [];
  public menu: any = [];
  public menu_code: any; public documenttype : any;
  constructor(private fb: FormBuilder, public commonservice: CommonService, public cms: CommonService, private route: ActivatedRoute) 
  { 
    this.mounoticeboard = this.fb.group({ //definition to cons
      document_type: ['']
    });
  }


  ngOnInit() 
  {
    this.documenttype = this.route.snapshot.paramMap.get('documentid'); 
    this.getMenu();
    this.getAlldata(this.documenttype);   
    this.mounoticeboard.get('document_type').setValue(+this.documenttype);//correct
  }

  ///////////////////////////////////////////////////////

  getAlldata(documenttype: any) 
  {
    let index = 0;
    this.commonservice.paramFunction('mouboardall', documenttype).subscribe(res => {
      this.data = res;
      this.data.forEach(e => {
        this.data[index].sn = index + 1;
        index++;
      });
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
  }

  ///////////////////////////////////////////////////////

  typeChange(event: any) 
  {
    this.menu_code = event.value;
    if (this.menu_code == 0) 
    {
      let index = 0;
      this.commonservice.getFunction('mouboardall/0').subscribe(res => {
        this.data = res;
        this.data.forEach(e => {
          this.data[index].sn = index + 1;
          index++;
        });
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
    else {
      let index = 0;
      this.commonservice.paramFunction('mouboardall', this.menu_code).subscribe(res => {
        this.data = res;
        this.data.forEach(e => {
          this.data[index].sn = index + 1;
          index++;
        });
        this.dataSource = new MatTableDataSource(this.data);
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      });
    }
  }
  
  getMenu() 
  {
    this.commonservice.getFunction('mouboardmenu').subscribe(res => {
      this.menu = res;
    });
  }  

  applyFilter(event: Event) 
  {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) 
    {
      this.dataSource.paginator.firstPage();
    }
  }


}
