import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-aboutus',
  templateUrl: './aboutus.component.html',
  styleUrls: ['./aboutus.component.scss']
})
export class AboutusComponent implements OnInit {

  public gallery: any = [];
  public dept_id: any;
  rootUrl = environment.rootUrl;
  constructor(private http: HttpClient, private dp: DatePipe, public commonservice: CommonService,public cms: CommonService) { }
  

  ngOnInit(): void {
    this.dept_id = 1;
    this.getDeptgallery(this.dept_id);
  }


  getDeptgallery(dept_id: any) {
    this.commonservice.paramFunction('gallery', this.dept_id).subscribe(res => {
      this.gallery = res;
    });
  }


  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    items: 4,
    margin: 10,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      }
    },
    nav: true
  }

}




