<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/recruitment.png" alt="" style="height: 25px;width: 25px;"> {{'RECRUITMENT.Title' | translate}}
                </div>
                <div class="card">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-6 col-sm-6 col-lg-6">
                                    <mat-form-field>
                                        <mat-label>Search</mat-label>
                                        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                                    </mat-form-field>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col-md-12 col-sm-12 col-lg-12">
                                    <div class="mat-elevation-z8" style="overflow: auto">
                                        <table mat-table [dataSource]="dataSource" matSort>

                                            <!-- ID Column -->
                                            <ng-container matColumnDef="sn">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                    {{'NOTICEBOARD.NBSNo' | translate}} </th>
                                                <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                                    mat-cell *matCellDef="let row"> {{row.sn}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="linkname">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                    {{'NOTICEBOARD.NBTitle' | translate}} </th>
                                                <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                                    mat-cell *matCellDef="let row"> {{row.linknamehn}} <br> {{row.linknameen}}</td>
                                            </ng-container>

                                            <!-- Progress Column -->
                                            <ng-container matColumnDef="issuedate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                    {{'NOTICEBOARD.NBStDate' | translate}}
                                                </th>
                                                <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                                    mat-cell *matCellDef="let row"> {{row.issuedate | date: 'dd/MM/yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="validitydate">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                    {{'NOTICEBOARD.NBEnDate' | translate}} </th>
                                                <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                                    mat-cell *matCellDef="let row"> {{row.validitydate | date: 'dd/MM/yyyy'}} </td>
                                            </ng-container>

                                            <ng-container matColumnDef="linkurl">
                                                <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                    [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                    {{'NOTICEBOARD.NBVDownloads' | translate}}
                                                </th>
                                                <td mat-cell *matCellDef="let row">                                                


                                                    <div *ngIf=" row.doctype=='P'">
                                                        <a [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                                            style="cursor: pointer;" target="_blank" href="{{rootUrl + row.linkurl}}">
                                                               <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                                                        </a>
                                                    </div>
                                                </td>
                                            </ng-container>


                                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                            <!-- Row shown when there is no matching data. -->
                                            <tr class="mat-row" *matNoDataRow>
                                                <td class="mat-cell" colspan="4">{{'NOTICEBOARD.NBVDownloads' | translate}}
                                                    "{{input.value}}"</td>
                                            </tr>
                                        </table>

                                        <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                                    </div>
                                </div>
                            </div>

                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>