import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-about-portal',
  templateUrl: './about-portal.component.html',
  styleUrls: ['./about-portal.component.scss']
})
export class AboutPortalComponent implements OnInit {

  constructor(public cms: CommonService,) { }

  ngOnInit(): void {
  }

}
