import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-otheractivities',
  templateUrl: './otheractivities.component.html',
  styleUrls: ['./otheractivities.component.scss']
})
export class OtheractivitiesComponent implements OnInit {


  public activitydetails: any = [];
  public data: any = [];
  public activitydesc: any = [];
  rootUrl = environment.rootUrl;

  constructor(private http: HttpClient, private dp: DatePipe, public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {
    this.getOtherActivity();
  }

  getOtherActivity() {
    this.commonservice.getFunction('otheractivity').subscribe(res => {
      this.data = res;
    }
    )
  };

}
