
<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/plans.png" alt="" style="height: 20px;width: 20px;"> {{'PLANS.Title' | translate}}
                </div>
                <div class="card">
                    <mat-card>
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="fill" class="full-width" class="col-md-6">     
                                        <mat-label>{{'PLANS.Type' | translate}}</mat-label>
                                        <mat-select (selectionChange)="planChange($event.value)" [(value)]="selected">
                                            <mat-option value="0">
                                               सभी/All
                                            </mat-option>
                                            <mat-option *ngFor="let d of plans" [value]="d.scheme_code">
                                                {{d.scheme_type}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>
                                <div class="col-md-6">
                                   
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                        <mat-form-field>
                                            <mat-label>Filter</mat-label>
                                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                                        </mat-form-field>                                    
                                        <div class="mat-elevation-z8 table table-bordered" style="overflow: auto">
                                            <table mat-table [dataSource]="dataSource" matSort>
                                                <!-- ID Column -->
                                                <ng-container matColumnDef="sn">
                                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                                        mat-header-cell *matHeaderCellDef mat-sort-header> {{'DIRECTORY.SNo' | translate}} </th>
                                                    <td mat-cell *matCellDef="let row"
                                                        [ngStyle]="{'color': cms.colorfront.value,'font-size': commonservice.fontsize.value +'px'}">
                                                        {{row.sn}} </td>
                                                </ng-container>
                                        
                                                <ng-container matColumnDef="planname">
                                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                                        mat-header-cell *matHeaderCellDef mat-sort-header> {{'DIRECTORY.Name' | translate}} </th>
                                                    <td mat-cell *matCellDef="let row"
                                                        [ngStyle]="{'color': cms.colorfront.value,'font-size': commonservice.fontsize.value +'px'}">
                                                        {{row.linknamehn}} </td>
                                                </ng-container>
                                        
                                                <ng-container matColumnDef="linkurl">
                                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                                        [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                                        {{'NOTICEBOARD.NBVDownloads' | translate}}
                                                    </th>
                                                    <td mat-cell *matCellDef="let row">    
                                                        <a style="cursor: pointer;" target="_blank" href="{{rootUrl + row.linkurl}}">
                                                             <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                                                        </a>                                        
                                                    </td>
                                                </ng-container>
                                        
                                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                        
                                                <!-- Row shown when there is no matching data. -->
                                                <tr class="mat-row" *matNoDataRow>
                                                    <td class="mat-cell" colspan="4">{{'DIRECTORY.NoRecord' | translate}} "{{input.value}}"</td>
                                                </tr>
                                            </table>
                                        
                                            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                                        </div>
                                </div>
                            </div>                          
                        </mat-card-content>
                    </mat-card>
                </div>
            </div>
        </div>
    </div>
</div>