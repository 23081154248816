<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/organization.png" alt="" style="height: 25px;width: 25px;"> &nbsp;
                    {{'ORGANIZATIONALCHART.Title' |
                    translate}}
                </div>
            </div>
        </div>
        <div id="cards_landscape_wrap-2">
            <div class="row">
                <div class="col-md-12">
                    <img src="assets/images/big/flowchart.jpg" alt="Paris">
                </div>
            </div>
        </div>
    </div>
</div>
