<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/achievement.png" alt="" style="height: 25px;width: 25px;">
                    {{'ACHIEVEMENTS.TitleAchievements' |
                    translate}}
                </div>
            </div>
        </div>
        <br>
        <div id="cards_landscape_wrap-2">
            <div class="row">
                <div class="col-md-12">
                    <div class="card h-100">
                        <div class="card-header text-center"
                                [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                2015-18
                        </div>
                        <div class="card-body">                               
                            <table id="table1">
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 100px;">{{'ACHIEVEMENTS.ParaTitle1' |
                                        translate}}</th>
                                        <td>
                                            {{'ACHIEVEMENTS.Para1Desc1'|translate}}
                                            <br>
                                            <ul>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc2'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc3'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc4'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc5'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc6'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc7'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc8'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para1Desc9'|translate}}
                                                </li>
                                            </ul>                                           
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 100px;">{{'ACHIEVEMENTS.ParaTitle2' |
                                        translate}}</th>
                                        <td>
                                            {{'ACHIEVEMENTS.Para2Desc1'|translate}}
                                            <br>
                                            <ul>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para2Desc2'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para2Desc3'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para2Desc4'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para2Desc5'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para2Desc6'|translate}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 100px;">{{'ACHIEVEMENTS.ParaTitle3' |
                                        translate}}</th>
                                        <td>
                                            -{{'ACHIEVEMENTS.Para3Desc1'|translate}}
                                            <br>
                                            -{{'ACHIEVEMENTS.Para3Desc2'|translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 100px;">{{'ACHIEVEMENTS.ParaTitle4' |
                                        translate}}</th>
                                        <td>
                                            {{'ACHIEVEMENTS.Para4Desc1'|translate}}
                                            <br>
                                            {{'ACHIEVEMENTS.Para4Desc2'|translate}}
                                            <ul>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para4Desc3'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para4Desc4'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para4Desc5'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Para4Desc6'|translate}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                            </table>                               
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                        <div class="card h-100">
                            <div class="card-header text-center"
                                [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                2019-20
                            </div>
                            <div class="card-body">
                                <table id="table1">
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 100px;">{{'ACHIEVEMENTS.Para2Title1' |
                                            translate}}</th>
                                        <td>
                                            -{{'ACHIEVEMENTS.Desc2Para1'|translate}}
                                            <br>
                                            -{{'ACHIEVEMENTS.Desc2Para2'|translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 200px;">{{'ACHIEVEMENTS.Para2Title2' |
                                            translate}}</th>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc2Para3'|translate}}
                                            <ul>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Desc2Para4'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Desc2Para5'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Desc2Para6'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Desc2Para7'|translate}}
                                                </li>
                                                <li>
                                                    -{{'ACHIEVEMENTS.Desc2Para8'|translate}}
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-center" style="padding-bottom: 10px;">{{'ACHIEVEMENTS.Para2Title3' |
                                            translate}}</th>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc2Para9'|translate}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
            <br>
            <div class="row">
                <div class="col-md-12">
                        <div class="card h-100">
                            <div class="card-header text-center"
                                [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                                2020-21
                            </div>
                            <div class="card-body">
                                <table id="table1" style="width: 100%;">
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                            {{'ACHIEVEMENTS.Para3Title1' |
                                            translate}}</th>
                                    </tr>
                                    <tr>
                                            <td>
                                                {{'ACHIEVEMENTS.Desc3Para1' |
                                                translate}}
                                            </td>
                                    </tr>
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px; word-break:break-all;">
                                            {{'ACHIEVEMENTS.Para3Title2' |
                                            translate}}</th>
                                    <tr>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc3Para2' |
                                            translate}} {{'ACHIEVEMENTS.Desc3Para3' |
                                            translate}} {{'ACHIEVEMENTS.Desc3Para4' |
                                            translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px; word-break:break-all;">
                                            {{'ACHIEVEMENTS.Para3Title3' |
                                            translate}}</th>                    
                                    </tr>  
                                    <tr>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc4Para1' |
                                            translate}} {{'ACHIEVEMENTS.Desc4Para2' |
                                            translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px;word-break:break-all;">{{'ACHIEVEMENTS.Para3Title4'|translate}}</th>                    
                                    </tr>
                                    <tr>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc4Para1' |
                                            translate}} {{'ACHIEVEMENTS.Desc5Para2' |
                                            translate}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px; word-break:break-all;">
                                            {{'ACHIEVEMENTS.Para3Title5' |
                                            translate}}</th>                    
                                    </tr>
                                    <tr>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc6Para1' |
                                            translate}}
                                        </td>
                                    </tr>                                    
                                    <tr>
                                        <th class="text-left" style="padding-bottom: 0px; word-break:break-all;">
                                            {{'ACHIEVEMENTS.Para3Title6' |
                                            translate}}</th>
                    
                                    </tr>
                                    <tr>
                                        <td>
                                            {{'ACHIEVEMENTS.Desc7Para1' |
                                            translate}}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card h-100">
                        <div class="card-header text-center"
                            [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                            2021-22
                        </div>
                        <div class="card-body">
                            <table id="table1" style="width: 100%;">
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para4Title1' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20211' |
                                        translate}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para4Title2' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20212' |
                                        translate}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-md-12">
                    <div class="card h-100">
                        <div class="card-header text-center"
                            [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                            2022-23
                        </div>
                        <div class="card-body">
                            <table id="table1" style="width: 100%;">
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para5Title1' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20231' |
                                        translate}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para5Title2' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20232' |
                                        translate}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para5Title3' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20233' |
                                        translate}}
                                    </td>
                                </tr>
                                <tr>
                                    <th class="text-left" style="padding-bottom: 0px; word-break:break-all;;">
                                        {{'ACHIEVEMENTS.Para5Title4' |
                                        translate}}</th>
                                </tr>
                                <tr>
                                    <td>
                                        {{'ACHIEVEMENTS.Desc20234' |
                                        translate}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>