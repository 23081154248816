
    <div class="container">
      <div class="col-sm-12 col-md-12 col-lg-12">
        <div class="text-center">
          <h3>{{ "FOOTER.SiteMap" | translate }}</h3>
        </div>
        <div id="site-map">
          <ul>
            <li>
              <a class="font-bold" [routerLink]="['/home']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}"> {{ "SITEMAP.Home" | translate }}</a>
            </li>
            <li>
              <a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}"> {{ "SITEMAP.Aboutus" | translate }}</a>
              <ul>
                <li><a [routerLink]="['/aboutus']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Aboutus" | translate }}</a></li>
                <li><a [routerLink]="['/reports']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.ActsnRules" | translate }}</a></li>
                <li><a [routerLink]="['/teamspc']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.TeamSPC" | translate }}</a></li>
                <li><a [routerLink]="['/whoswho']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Whoswho" | translate }}</a></li>
                <li><a [routerLink]="['/organizationalchart']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.OrganizationChart" | translate }}</a></li>
                <!-- <li><a [routerLink]="['/scheme']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.FlagshipSchemes" | translate }}</a></li> -->
                <li><a [routerLink]="['/plans']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.PlansnProgramme" | translate }}</a></li>
              </ul>
            </li>
            <li>
                <a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Activities" | translate }}</a>
                <ul>
                    <li><a [routerLink]="['/taskforce']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Taskforce" | translate }}</a></li>
                    <li><a [routerLink]="['/otheractivities']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.OtherActivities" | translate }}</a></li>
                  
                </ul>
            </li>
              <li><a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Noticeboard" | translate }}</a>
                        <ul>
                            <li><a [routerLink]="['/noticeboard']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.News" | translate }}</a></li>
                            <li><a [routerLink]="['/noticeboard']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Tender" | translate }}</a></li>
                            <li><a [routerLink]="['/noticeboard']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Publications" | translate }}</a></li>
                            <li><a [routerLink]="['/noticeboard']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Reports" | translate }}</a></li>
                            <li><a [routerLink]="['/recruitmentnotice']" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Recruitment" | translate }}</a></li>

                        </ul>
                </li>           
                <li><a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Photogalary" | translate }}</a>
                <ul>
                    <li><a [routerLink]="['/gallery']" target="_blank" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.Photogalary" | translate }}</a></li>                
                </ul></li>
                <li><a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.RTI" | translate }}</a>
                  <ul>
                    <li><a [routerLink]="['/rti']" target="_blank" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.RTI" | translate }}</a></li>
                  </ul>
                </li>
                <li><a class="font-bold" href="" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.ContactUs" | translate }}</a>
                  <ul>
                    <li><a [routerLink]="['/contact-us']" target="_blank" [ngStyle]="{'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value}">{{ "SITEMAP.ContactUs" | translate }}</a></li>
                  </ul>
                </li>
                <!-- <li><a class="font-bold" href="">{{ "SITEMAP.LogIn" | translate }}</a></li> -->
          </ul>
        </div>
      </div>
    </div>
