import { Component, OnInit } from '@angular/core';
import { ReactiveFormsModule ,FormArray, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../../services/common.service';
import { environment } from 'src/environments/environment';
import { Router, RouterEvent } from '@angular/router';

export class VisitorCount {
  id: number;
  visitor_count: number;
}
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  [x: string]: any;

  public visitcount: any;
  public counter: number = 0;
  
  public now: Date = new Date();
  public dateYesterday: Date = new Date();
  total_visitor: any;
  data: Object;
  visitor: number;
  visitor1 = new VisitorCount(); 
  visitor_count1 :number;
  visitor_id: number;

  visitor_count=0;

  // VisitorCount =  {
  //   id: 1,
  //   visitor_count: '',
  //   accept: true
  // }

  constructor(private router: Router, private http: HttpClient, private dp: DatePipe, public cms: CommonService) { }

  ngOnInit() {
   
    this.getVisitorCount();
    //this.VisitorUpdate('1')
    
    this.dateYesterday = new Date(this.dateYesterday.setDate(this.dateYesterday.getDate() - 1));
  }

  onChange(e: any) {
    console.log(e)
    console.log(this.VisitorCount.accept);
    console.log(this.VisitorCount)
  }
 

  public getVisitorCount()
  {   
    this.cms.getVisitorCount('visitor_count').subscribe(res => {     
        // this.visitor =res[0].visitor_count;
        this.VisitorUpdate(res[0].visitor_count)
    });
   
  }

  public VisitorUpdate(visitor: number) {
    this.visitor=visitor+1;
    let i = 1;    
    let visitor_count1 = visitor + i;
    let body = {
      id: '1',
      visitor_count: this.visitor,
    }
    this.cms.updatedata(body, 'visitor_count').subscribe(res => {     
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    items: 4,
    margin: 10,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      }
    },
    nav: true
  }
}
