<div class="my-3" id="b-homedb">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-12">
                    <div class="text-white">
                        <div class="card-header text-center"
                            [ngStyle]="{'color': '#000000', 'font-size': cms.fontsize.value +'px'}">
                            <img src="../../assets/images/logo/trophy.png" alt="" style="height: 25px;width: 25px;">
                            {{'ABOUTBS.AboutBs' |
                            translate}}
                        </div>
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-md-12">
                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}"
                                        class="text-justify">
                                        {{'ABOUTBS.Details1'|
                                        translate}}</p>
                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}"
                                        class="text-justify">
                                        {{'ABOUTBS.Details2'|
                                        translate}}</p>
                                    <a [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"
                                        href="https://sdgspc.cg.gov.in/bestpractice/#/home" target="_blank">
                                        {{'HOME.ViewMore' | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        </div>
</div>