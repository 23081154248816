<div class="row">
    <div class="col-md-12">
        <mat-card>
            <div class=" p-2 text-white text-center" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Note1' | translate}} <i
                    class="fa fa-asterisk text-danger" aria-hidden="true" ></i> {{'FEEDBACK.Note2' | translate}}</div>
            <mat-card-content>
                <!-- <mat-card-title class="text-center"><u>Feedback Form</u> </mat-card-title> -->
                <!-- <hr> -->
                <form (ngSubmit)="save(feedbackForm.value)" [formGroup]="feedbackForm">
                    <mat-card>
                        <mat-card-header class=" text-white" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                            <mat-card-subtitle [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Title' | translate}}</mat-card-subtitle>
                        </mat-card-header>

                        <mat-card-content>

                            <div class="row">
                                <div class="col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Fname' | translate}} <span class="required"></span>
                                        </mat-label>
                                        <input matInput formControlName="firstname" #firstname maxlength="100">
                                        <mat-hint align="end">{{firstname.value.length}} / 100</mat-hint>
                                        <!-- <mat-error *ngIf="isValidInput('firstname')">
                                                                            <mat-error *ngIf="deptcontactForm.controls['lastname'].errors.required">
                                                                                Link Name is <strong>required</strong>.
                                                                            </mat-error>
                                                                        </mat-error> -->
                                    </mat-form-field>
                                </div>


                                <div class="col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Lname' | translate}}<span class="required"></span>
                                        </mat-label>
                                        <input matInput formControlName="lastname" #linkname maxlength="100">
                                        <mat-hint align="end">{{linkname.value.length}} / 100</mat-hint>
                                        <!-- <mat-error *ngIf="isValidInput('linkname')">
                                                                            <mat-error *ngIf="deptcontactForm.controls['linkname'].errors.required">
                                                                                Link Name is <strong>required</strong>.
                                                                            </mat-error>
                                                                        </mat-error> -->
                                    </mat-form-field>
                                </div>


                                <div class="col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Email' | translate}}<span class="required"></span>
                                        </mat-label>
                                        <input matInput formControlName="emailid" #emailid maxlength="100">
                                        <mat-hint align="end">{{emailid.value.length}} / 100</mat-hint>
                                        <!-- <mat-error *ngIf="isValidInput('emailid')">
                                            <mat-error *ngIf="deptcontactForm.controls['emailid'].errors.required">
                                                Link Name is <strong>required</strong>.
                                            </mat-error>
                                        </mat-error> -->
                                    </mat-form-field>
                                </div>

                                <div class="col-md-6">
                                    <mat-form-field appearance="outline">
                                        <mat-label [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.Contact' | translate}}<span class="required"></span>
                                        </mat-label>
                                        <input matInput formControlName="contactno" #contactno number maxlength="10">
                                        <mat-hint align="end">{{contactno.value.length}} /10</mat-hint>
                                        <!-- <mat-error *ngIf="isValidInput('contactno')">
                                                                                <mat-error *ngIf="deptcontactForm.controls['contactno'].errors.required">
                                                                                    Website URL is <strong>required</strong>.
                                                                                </mat-error>
                                                                            </mat-error> -->
                                    </mat-form-field>
                                </div>

                                <div class="col-md-12">
                                    <mat-form-field appearance="outline" class="col-md-12">
                                        <mat-label [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.FBMsg' | translate}}<span class="required"></span>
                                        </mat-label>
                                        <input matInput formControlName="message" #message maxlength="1000">
                                        <mat-hint align="end">{{message.value.length}} / 1000</mat-hint>
                                        <mat-error *ngIf="isValidInput('message')">
                                            <mat-error *ngIf="feedbackForm.controls['message'].errors.required" [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">
                                                Feedback Message is <strong>required</strong>.
                                            </mat-error>
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                                <!-- <div class="col-md-6">
                                    <mat-form-field appearance="fill">
                                        <mat-label>Choose a Isssue Date</mat-label>
                                        <input matInput [max]="today" [matDatepicker]="picker" formControlName="issuedate" (dateInput)="addissuedate('input', $event)">
                                        <mat-datepicker-toggle matSuffix [for]="picker">
                                        </mat-datepicker-toggle>
                                        <mat-datepicker #picker></mat-datepicker>
                                    </mat-form-field>
                                </div> -->
                            </div>
                        </mat-card-content>
                    </mat-card>

                    <mat-card-header>
                        <div class="row">
                            <button type="submit" class="btn btn-success" [ngStyle]="{'font-size': cms.fontsize.value +'px'}">{{'FEEDBACK.SaveButton' | translate}}</button> &nbsp;&nbsp;
                        </div>
                    </mat-card-header>
                </form>
            </mat-card-content>
        </mat-card>









    </div>
</div>