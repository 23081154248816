            <mat-card>
                <div class="card h-100">
                    <div class="card-header text-center"
                        [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/rules.png" alt="" style="height: 25px;width: 25px;"> {{'REPORTS.Title' | translate}}
                    </div>
                </div>
                <mat-card-content>
                    <mat-form-field>
                        <mat-label>खोजें/Search</mat-label>
                        <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                    </mat-form-field>

                    <div class="mat-elevation-z8" style="overflow: auto">
                        <table mat-table [dataSource]="dataSource" matSort>

                            <!-- ID Column -->
                            <ng-container matColumnDef="sn">
                                <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                    > {{'REPORTS.TSNo' | translate}} </th>
                                <td mat-cell *matCellDef="let row" [ngStyle]="{'color': cms.colorfront.value,'font-size': commonservice.fontsize.value +'px'}">
                                    {{row.sn}} </td>
                            </ng-container>

                            <ng-container matColumnDef="linkname">
                                <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                    > {{'REPORTS.TTitle' | translate}} </th>
                                    <td mat-cell *matCellDef="let row" [ngStyle]="{'color': cms.colorfront.value,'font-size': commonservice.fontsize.value +'px'}" >
                                        <ng-container *ngIf="cms.lang.value=='English'">
                                            {{row.linkname}} 
                                        </ng-container>
                                        <ng-container *ngIf="cms.lang.value=='Hindi'">
                                            {{row.linkname_hn}}
                                        </ng-container>
                                    </td>                    
                            </ng-container>
                            
                            <ng-container matColumnDef="menu_tab_linkurl">
                                <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}" mat-header-cell *matHeaderCellDef mat-sort-header
                                    > {{'REPORTS.TVDownloads' | translate}}</th>
                                <td mat-cell *matCellDef="let row">
                                    <a style="cursor: pointer;" target="_blank" href="{{rootUrl + row.menu_tab_linkurl}}">
                                          <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                                    </a>
                                </td>
                            </ng-container>


                            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                            <!-- Row shown when there is no matching data. -->
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="4">{{'REPORTS.NoRecord' | translate}} "{{input.value}}"</td>
                            </tr>
                        </table>

                        <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                    </div>

                </mat-card-content>

            </mat-card>
    
