import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';


@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {

  public dept_id: any;
  public gallery: any = [];
  rootUrl = environment.rootUrl;
  displayedColumns: string[] = ['sn', 'picurl', 'aboutpic']; //deptname_hn
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  constructor(public http: HttpClient, public dp: DatePipe, public cms: CommonService) { }

  ngOnInit(): void {
    this.dept_id = 1;
    this.getDeptgallery(this.dept_id);
  }

  getDeptgallery(dept_id: any) 
  {
    let index = 0;
    this.cms.paramFunction('allpicgallery', dept_id).subscribe(res => {
      this.gallery = res;
      this.gallery.forEach(e => {
        this.gallery[index].sn = index + 1;
        index++;
      });
      this.dataSource = new MatTableDataSource(this.gallery);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    });
   
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  hitAnchor1(e) {
    console.log("Events", e);
    // alert("You are being redirected to an external website.");
  }


}

