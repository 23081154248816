<div class="my-3" id="b-homedb">
  <div class="container-fluid">

          <div  *ngIf="cms.lang.value=='English'" id="content">
            
            <div
              id="p_p_id_103_"
              class="portlet-boundary portlet-boundary_103_ portlet-static portlet-static-end"
            >
              <span id="p_103"></span>
              <div class="portlet-body"></div>
            </div>
            <div class="columns-1" id="main-content" role="main">
              <div class="portlet-layout">
                <div class="portlet-column portlet-column-only" id="column-1">
                  <div
                    class="portlet-dropzone portlet-column-content portlet-column-content-only"
                    id="layout-column_column-1"
                  >
                    <div
                      id="p_p_id_56_INSTANCE_6kizWYEVhuAZ_"
                      class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                    >
                      <span id="p_56_INSTANCE_6kizWYEVhuAZ"></span>
                      <div class="portlet-body">
                        <section class="portlet" id="portlet_56_INSTANCE_6kizWYEVhuAZ">
                          <header class="portlet-topper" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                            <h2 class="portlet-title">
                              <span tabindex="0" class="portlet-title-text" [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                              >Terms & Condition</span>
                            </h2>
                            <ul
                              class="portlet-topper-toolbar"
                              id="portlet-topper-toolbar_56_INSTANCE_6kizWYEVhuAZ"
                            ></ul>
                          </header>
                          <div class="portlet-content">
                            <div class="portlet-content-container" style="">
                              <div class="portlet-body">
                                <div
                                  class="journal-content-article"
                                  id="article_10153_10179_37259_2.3"
                                >
                                  <div style="margin-left: 5px; margin-right: 5px">
                                    <p style="text-align: justify">
                                      <br />
                                      <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">This website is updated and maintained by Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh.</strong>
                                    </p>
                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      Though all efforts have been made to ensure the accuracy of the content on this website, the same should not be
                                      construed as a statement of law or used for any legal purposes. In case of any ambiguity or doubts, users are advised to
                                      verify/check with the Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh and/or other source(s), and to obtain
                                      appropriate professional advice.
                                    </p>

                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      Under no circumstances will Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh be liable for any expense, loss or
                                      damage including, without limitation, indirect or consequential loss or damage, or any expense, loss or damage
                                      whatsoever arising from use, or loss of use, of data, arising out of or in connection with the use of this website.
                                    </p>

                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      These terms and conditions shall be governed by and construed in accordance with the Indian Laws. Any dispute arising
                                      under these terms and conditions shall be subject to the jurisdiction of the courts of India.
                                    </p>

                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      The information posted on this website could include hypertext links or pointers to information created and maintained
                                      by non-Government / private organizations. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh is providing these
                                      links and pointers solely for your information and convenience. When you select a link to an outside website, you are
                                      leaving the Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh website and are subject to the privacy and security
                                      policies of the owners/sponsors of the outside website. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh does not
                                      guarantee the availability of such linked pages at all times. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh
                                      cannot authorize the use of copyrighted materials contained in linked websites. Users are advised to request such
                                      authorization from the owner of the linked website. Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh does not
                                      guarantee that linked websites comply with Indian Government Web Guidelines.
                                    </p>
                                    <p style="text-align: justify">
                                      <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">Disclaimer</strong>
                                    </p>
                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      This website of the Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh is being maintained for information purposes
                                      only. Even though every effort is taken to provide accurate and up to date information, officers making use of the
                                      circulars posted on the website are advised to get in touch with the Rajya Niti Aayog, Chhattisgarh, Government Of
                                      Chhattisgarh whenever there is any doubt regarding the correctness of the information contained therein. In the event of
                                      any conflict between the contents of the circulars on the website and the hard copy of the circulars issued by State
                                      Planning Commission, Government Of Chhattisgarh the information in the hard copy should be relied upon and the matter
                                      shall be brought to the notice of the Rajya Niti Aayog, Chhattisgarh, Government Of Chhattisgarh.
                                    </p>
                                  </div>
                                </div>
                                <div class="entry-links"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
          </div>


          <div  *ngIf="cms.lang.value=='Hindi'" id="content">
            
            <div
              id="p_p_id_103_"
              class="portlet-boundary portlet-boundary_103_ portlet-static portlet-static-end"
            >
              <span id="p_103"></span>
              <div class="portlet-body"></div>
            </div>
            <div class="columns-1" id="main-content" role="main">
              <div class="portlet-layout">
                <div class="portlet-column portlet-column-only" id="column-1">
                  <div
                    class="portlet-dropzone portlet-column-content portlet-column-content-only"
                    id="layout-column_column-1"
                  >
                    <div
                      id="p_p_id_56_INSTANCE_6kizWYEVhuAZ_"
                      class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                    >
                      <span id="p_56_INSTANCE_6kizWYEVhuAZ"></span>
                      <div class="portlet-body">
                        <section class="portlet" id="portlet_56_INSTANCE_6kizWYEVhuAZ">
                          <header class="portlet-topper" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                            <h2 class="portlet-title">
                              <span tabindex="0" class="portlet-title-text" [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">नियम और शर्तें</span>
                            </h2>
                            <ul
                              class="portlet-topper-toolbar"
                              id="portlet-topper-toolbar_56_INSTANCE_6kizWYEVhuAZ"
                            ></ul>
                          </header>
                          <div class="portlet-content">
                            <div class="portlet-content-container" style="">
                              <div class="portlet-body">
                                <div
                                  class="journal-content-article"
                                  id="article_10153_10179_37259_2.3"
                                >
                                  <div style="margin-left: 5px; margin-right: 5px">
                                    <p style="text-align: justify">
                                      <br />
                                      <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">इस वेबसाइट की अद्यतन जानकारी एवं अनुरक्षण राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार द्वारा किया जाता है।</strong>
                                    </p>
                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    किसी भी परिस्थिति में राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार उपभोक्तान द्वारा डाटा के उपयोग अथवा उसके उपयोग से होने वाली किसी
                                    भी प्रकार की हानि जो वेबसाइट के उपयोग अथवा तत्संबंधी कार्यकलापों से हो सकती है, के किसी भी प्रत्यक्ष अथवा परिणामी नुकसान
                                    या व्योय सहित किसी भी प्रकार की हानि, व्य य अथवा नुकसान के लिए जिम्मे वार नहीं होगा।
                                    </p>

                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    इन नियम एवं शर्तों की अनुपालना तथा अभिशासन भारतीय कानूनों के अनुरूप होगा। इन नियमों एवं शर्तों के संबंध में होने वाले
                                    कोई भी विवाद भारत के न्याोयालयों के न्यािय-निर्णयाधीन होंगे।
                                    </p>


                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                    इस वेबसाइट पर दी गई किसी भी सूचना में हाइपर टेक्ट्के लिंक अथवा प्वा इंटर्स शामिल हो सकते हैं जो गैर-सरकारी/प्राइवेट
                                    संगठनों द्वारा तैयार एवं अनुरक्षित की जाती हैं। राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार ये लिंक और प्वारइंटर्स केवल आपकी
                                    सुविधा और सूचनार्थ प्रदान कर रहा है। जब आप वेबसाइट से बाहर किसी लिंक का चयन करते हैं तो आप राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़
                                    सरकार की वेबसाइट से बाहर चले जाते हैं और यह बाहरी वेबसाइट के मालिकों/प्रायोजकों की गोपनीयता एवं सुरक्षा नीति के अध्यीधीन
                                    होता है। राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार ऐसे सभी लिंक पृष्ठोंय की हर समय उपलब्धधता की गारंटी नहीं लेता है। राज्य योजना
                                    आयोग, छत्तीसगढ़ सरकार इन लिंक वेबसाइटों में निहित कॉपीराइट सामग्रियों के उपयोग का प्राधिकार नहीं दे सकता। उपभोक्ताबओं को
                                    परामर्श दिया जाता है कि वे ऐसा प्राधिकार लिंक वेबसाइटों के मालिकों से प्राप्त‍ करें। राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार
                                    यह गारंटी नहीं देता कि लिंक वेबसाइटें भारत सरकार के वेब दिशानिर्देशों की अनुपालना करते हैं।
                                    </p>


                                    <p style="text-align: justify" [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">
                                      खंडन
                                    </p>

                                    <p style="text-align: justify">
                                      <strong [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}">राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार की वेबसाइट का अनुरक्षण केवल सूचना के प्रयोजनार्थ किया जा रहा है। हालांकि सटीक और
                                      अद्यतन सूचना प्रदान करने का हरसंभव प्रयास किया गया है फिर भी राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार की वेबसाइट पर डाले गये
                                      परिपत्रों का प्रयोग करने वाले अधिकारियों को परामर्श दिया जाता है कि इनमें निहित किसी भी सूचना की सटीकता के संबंध में
                                      संदेह होने पर राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार से सम्पसर्क करें। राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार द्वारा जारी किये गये
                                      परिपत्रों की हार्ड प्रतियों एवं वेबसाइट पर डाले गये परिपत्रों की विषय-वस्तुय में कोई भी विवाद होने की स्थिति में हार्ड
                                      प्रति में दी गई सूचना को सही माना जाए और यह जानकारी राज्य नीति आयोग, छत्तीसगढ़, छत्तीसगढ़ सरकार के ध्यासन में लाई जाए।</strong>
                                    </p>                                   
                                  </div>
                                </div>
                                <div class="entry-links"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
          </div>

  </div>
</div>

