<div class="my-3" id="b-homedb">
  <div class="container-fluid">

          <div *ngIf="cms.lang.value == 'English'" id="content">
            <div class="columns-1" id="main-content" role="main">
              <div class="portlet-layout">
                <div class="portlet-column portlet-column-only" id="column-1">
                  <div
                    class="portlet-dropzone portlet-column-content portlet-column-content-only"
                    id="layout-column_column-1"
                  >
                    <div
                      id="p_p_id_56_INSTANCE_zXtnG7NOL4qw_"
                      class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                    >
                      <span id="p_56_INSTANCE_zXtnG7NOL4qw"></span>
                      <div class="portlet-body">
                        <section class="portlet" id="portlet_56_INSTANCE_zXtnG7NOL4qw">
                          <header
                            class="portlet-topper"
                            [ngStyle]="{
                              'background-color': cms.colorfront.value,
                              color: cms.colorcode.value,
                              'font-size': cms.fontsize.value + 'px'
                            }"
                          >
                            <h3 class="portlet-title">
                              <span
                                tabindex="0"
                                class="portlet-title-text"
                                [ngStyle]="{
                                  color: cms.colorcode.value,
                                  'font-size': cms.fontsize.value + 'px'
                                }"
                                >Accessibility Statement</span
                              >
                            </h3>
                            <ul
                              class="portlet-topper-toolbar"
                              id="portlet-topper-toolbar_56_INSTANCE_zXtnG7NOL4qw"
                            ></ul>
                          </header>
                          <div class="portlet-content">
                            <div class="portlet-content-container" style="">
                              <div class="portlet-body">
                                <div
                                  class="journal-content-article"
                                  id="article_10153_10179_12514_4.4"
                                >
                                  <div style="margin-left: 5px; margin-right: 5px">
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      We are committed to ensure that the Rajya Niti Aayog, Chhattisgarh, portal is accessible to all the users
                                      irrespective of device in use, technology or
                                      ability. It has been built, with an aim, to provide
                                      maximum accessibility and usability to its users. As
                                      a result, this portal can be viewed from a variety
                                      of devices, such as web-enabled mobile devices, WAP
                                      phones, PDAs and so on.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      We have put in our best efforts to ensure that all
                                      the information on this portal is accessible to
                                      people with disabilities. For example, a user with
                                      visual disability can access this portal using
                                      assistive technologies, such as screen readers and
                                      magnifiers.
                                      <br />
                                      We also aim to be standards compliant and follow
                                      principles of usability and universal design, which
                                      should help all visitors of this portal.<br />
                                      If you have any problem or suggestion regarding the
                                      accessibility of this portal, please contact us.
                                    </p>
                                    <ul>
                                      <li
                                        style="margin-left: 40px; text-align: justify"
                                        [ngStyle]="{
                                          color: cms.colorfront.value,
                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                        }"
                                      >
                                        Learn about accessibility features.
                                      </li>
                                      <li
                                        style="margin-left: 40px; text-align: justify"
                                        [ngStyle]="{
                                          color: cms.colorfront.value,
                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                        }"
                                      >
                                        Learn about accessibility options.
                                      </li>
                                    </ul>
                                    <hr />
                                    <h2
                                      class="subheading"
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Accessibility Features
                                    </h2>

                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      The accessibility features&nbsp;of Rajya Niti Aayog, Chhattisgarh, portal, Govt. of
                                      Chhattisgarh are:
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>1. Skip to Main Content</strong>: Quick
                                      access to the core content on the page is provided
                                      without going through repetitive navigation using
                                      the keyboard.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>2. Skip to Navigation</strong>: Quick access
                                      to the navigation pane is provided that enables
                                      accessing the different sections, such as Citizens,
                                      Government, and Directories.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>3. Accessibility Options</strong>: Options
                                      to change the size of the text and set a colour
                                      scheme are provided. For example, if you are using a
                                      mobile device to access this portal, the text might
                                      appear smaller on the screen that makes it difficult
                                      to read. In such a situation, you can use this
                                      option to increase the size of the text for clear
                                      visibility and better readability.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>4. Descriptive Link Text</strong>: Brief
                                      description of a link is provided using descriptive
                                      phrases instead of just using words such as 'Read
                                      more' and 'Click here' as linked text. For example,
                                      if a link opens a PDF file, the description
                                      specifies its file size. Further, if a link opens a
                                      Web site in a new window, the description specifies
                                      the&nbsp;same.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>5. Table Headers</strong>: The table
                                      headings are marked and associated with their
                                      corresponding cells in each row. For example, if
                                      there are 30 rows and 5 columns in a table, it is
                                      difficult for a user with visual disability to
                                      identify which data cell belongs to which header. In
                                      this situation, an assistive device such as a screen
                                      reader can read the column header of any cell for
                                      the user. In addition, captions are also specified
                                      for each table that act as labels and indicate what
                                      data is provided in the table.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>6. Headings</strong>: The Web page content
                                      is organized using appropriate headings and
                                      subheadings that provide a readable structure. H1
                                      indicates the main heading, whereas H2 indicates a
                                      subheading. In addition, for the screen reader
                                      users, there are hidden headings in this portal that
                                      are read out by a screen reader for better
                                      readability. For example, the Navigation pane has
                                      been given a hidden heading as Global Navigation.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>7. Titles</strong>: An appropriate name for
                                      each Web page is specified that helps you to
                                      understand the page content easily.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>8. Alternate Text</strong>: Brief
                                      description of an image is provided for users with
                                      visual disability. If you are using a browser that
                                      supports only text or have turned off the image
                                      display, you can still know what the image is all
                                      about by reading the alternate text in absence of an
                                      image.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>9. Explicit Form Label Association</strong>:
                                      A label is linked to its respective control, such as
                                      text box, check box, radio button, and drop-down
                                      list. This enables the assistive devices to identify
                                      the labels for the controls on a form.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>10. Consistent Navigation Mechanism</strong
                                      >: Consistent style of presentation throughout the
                                      portal is incorporated.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>11. Expandable and Collapsible Lists</strong
                                      >: Lists of items are provided that can be expanded
                                      to view a list of sub-items or can be shrunk using a
                                      keyboard, mouse, or screen reader. There are many
                                      Web pages in this portal that contain expandable and
                                      collapsible lists. Each item in the list is preceded
                                      by the '+' symbol that indicates that this item is
                                      expandable. This symbol has been given alternate
                                      text to enable the screen readers to identify the
                                      state of the symbol to notify users with visual
                                      disability. When the '+' symbol is clicked, it
                                      changes to '–' that indicates that this item
                                      is&nbsp;collapsible. Further, the alternate text is
                                      updated to indicate the same and a list of sub items
                                      is displayed. If now the '-' symbol is clicked, it
                                      changes to '+'. Further, the alternate text is again
                                      updated and the list of sub items goes away, which
                                      is referred to as collapsing.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>12. Keyboard Support</strong>: The portal
                                      can be browsed using a keyboard by pressing the Tab
                                      and Shift + Tab keys.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>13. Customized Text Size</strong>: The size
                                      of the text on the Web page can be changed either
                                      through the browser or through the accessibility
                                      options feature.
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <strong>14. JavaScript Independent</strong>: Web
                                      page information and functionalities are independent
                                      of JavaScript, irrespective of the browser support
                                      for the scripting language.
                                    </p>
                                    <h2
                                      class="subheading"
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Accessibility Options
                                    </h2>

                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Do you find it difficult to read the contents on the
                                      screen?
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Is the displayed information not clearly visible to
                                      you?
                                    </p>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      If yes, use the accessibility options provided by
                                      this portal to control the screen display. These
                                      options allow changing the text size and colour
                                      scheme for clear visibility and better readability.
                                    </p>
                                    <h2
                                      class="subheading"
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Changing the Text Size
                                    </h2>

                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      Changing the size of the text refers to making the
                                      text appearing smaller or bigger from its standard
                                      size. There are&nbsp;three options provided to you
                                      to set the size of the text that affects the
                                      readability. These are:
                                    </p>
                                    <ul>
                                      <li
                                        style="text-align: justify"
                                        [ngStyle]="{
                                          color: cms.colorfront.value,
                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                        }"
                                      >
                                        <strong>&nbsp;Largest</strong>: Displays
                                        information in the largest font size.
                                      </li>
                                      <li
                                        style="text-align: justify"
                                        [ngStyle]="{
                                          color: cms.colorfront.value,
                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                        }"
                                      >
                                        <strong>&nbsp;Larger</strong>:&nbsp; Displays
                                        information in a font size larger than the
                                        standard font size.
                                      </li>
                                      <li
                                        style="text-align: justify"
                                        [ngStyle]="{
                                          color: cms.colorfront.value,
                                          'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                        }"
                                      >
                                        &nbsp;<strong>Medium</strong>: Displays
                                        information in a standard font size, which is the
                                        default size.
                                      </li>
                                    </ul>
                                    <p
                                      style="text-align: justify"
                                      [ngStyle]="{
                                        color: cms.colorfront.value,
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }"
                                    >
                                      <br />
                                      To change the text size,&nbsp;click&nbsp;the
                                      text-size icons present at the top of any page.
                                    </p>
                                  </div>
                                </div>
                                <div class="entry-links"></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
          </div>

          <div *ngIf="cms.lang.value == 'Hindi'" id="content" style="">
            <div class="columns-1" id="main-content" role="main" style="">
              <div class="portlet-layout" style="">
                <div
                  class="portlet-column portlet-column-only"
                  id="column-1"
                  style=""
                >
                  <div
                    class="portlet-dropzone portlet-column-content portlet-column-content-only"
                    id="layout-column_column-1"
                    style=""
                  >
                    <div
                      id="p_p_id_56_INSTANCE_zXtnG7NOL4qw_"
                      class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                      style=""
                    >
                      <span id="p_56_INSTANCE_zXtnG7NOL4qw"></span>
                      <div class="portlet-body" style="">
                        <section class="portlet" id="portlet_56_INSTANCE_zXtnG7NOL4qw">
                          <header class="portlet-topper" [ngStyle]="{
                            'background-color': cms.colorfront.value,
                            color: cms.colorcode.value,
                            'font-size': cms.fontsize.value + 'px'
                          }">
                            <h2 class="portlet-title">
                              <span tabindex="0" class="portlet-title-text"
                              [ngStyle]="{
                                
                                color: cms.colorcode.value,
                                'font-size': cms.fontsize.value + 'px'
                              }">अभिगम्यता कथन</span
                              >
                            </h2>
                            <ul
                              class="portlet-topper-toolbar"
                              id="portlet-topper-toolbar_56_INSTANCE_zXtnG7NOL4qw"
                            ></ul>
                          </header>
                          <div class="portlet-content" style="">
                            <div
                              class="portlet-content-container"
                              style=""
                            >
                              <div class="portlet-body" style="">
                                <div
                                  class="journal-content-article"
                                  id="article_10153_10179_12514_4.4"
                                  style=""
                                >
                                  <div
                                    style="
                                      margin-left: 5px;
                                      margin-right: 5px;
                                      
                                    "
                                  >
                                    
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      हमारे द्वारा सुनिश्चय किया गया है कि राज्य नीति आयोग, छत्तीसगढ़
                                      द्वारा वेबसाइट की प्राप्ति हेतु सभी उपयोगकर्ताओं को
                                      इसकी उपयोग, तकनीकी एवं योग्यता अनुसार हो जिसके लिये
                                      हमारे द्वारा अधिकतम सेवा उपयोगिता हेतु उपयोगकर्ता को
                                      दिये जाने का उददेश्य है जैसा कि परिणाम है कि इस
                                      वेबसाइट को विभिन्न प्रकार के उपकरणों से देखा जा सकेगा
                                      । जैसा कि वेब अनेबल्ड मोबाइल डिवाइस, डब्लयू
                                      .ए.पी. फोन्स, पी.डी.ए. एवं अन्य हमारे द्वारा अपने
                                      पूर्ण सार्मथ्य अनुसार जानकारी लोगों को वेबसाइट में
                                      दिये जाने हेतु दिया गया है ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      उदाहणार्थ एक उपयोगकर्ता द्वारा दृश्य असमर्थतता के
                                      कारण वेबसाइट का उपयोग तकनीकी रूप से टच स्क्रीन, से
                                      पढने एवं बडा करने शामिल है ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      हमारा यह भी उददेश्य है कि मानक रूप से अनुपालन एवं
                                      सिधान्तों के अनुरूप पूर्ण रूप से डिजाइन का
                                      उपयोगीकरण हो । जिससे सभी परिदर्शक को इस वेबसाइट से
                                      मदद मिलना चाहिये ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      यदि आपको कोर्इ समस्या हो या कोइ राय इस वेबसाइट के
                                      प्राप्ति हेतु देना चाहे तो कृपया हमसे संपर्क करे ।
                                    </p>
                                    <ul>
                                      <li style="margin-left: 40px; text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        विषय सामाग्री का अधिदर्शन.
                                      </li>
                                      <li style="margin-left: 40px; text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        विषय सामाग्री के सीख हेतु विकल्प.
                                      </li>
                                    </ul>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      विषय सामाग्री का अधिदर्शन राज्य नीति आयोग, छत्तीसगढ़ वेबसाइट विषय
                                      सामाग्री, छ.ग. शासन निम्नानुसार है
                                    </p>
                                    <ol style="margin: 15px;">
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>मुख्य विषय में जाना </strong>: मुख्य विषय
                                        में जाने के लिये जल्द पहुंच करे जिसके लिये की-
                                        बोर्ड एपिटेटिव नेविगेशन उपयोग के बगैर पेज
                                        अधिदर्शित है ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>नेविगेशन में जाना</strong>&nbsp;निवेगेशन
                                        पेज में जाने के लिये जल्द पंहुच करे जिसमें आप
                                        विभिन्न साइट जैसा कि नागरिक, शासन एवं संचालनालय
                                        अधिदर्शित कर सकेंगें ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>विषय विकल्प</strong>: विषय विकल्प के लिये,
                                        पाठय के आकार में जाकर योजना का रंग दिया जाएगा
                                        उदाहरण के लिये यदि आप अपने मोबाइल में इस वेबसाइट
                                        का उपयोग करते हैं तो विषय सामाग्री, छोटा दृष्य में
                                        आयेगा जो कि पढने में कठिन होता है एैसी स्थिति में
                                        आकार को बडा करने के लिये अत: स्पष्ट दिखायी देने के
                                        लिये बेहतर आकार की व्यवस्था टेक्सट (पाठय) की जा
                                        सकेगी ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>विवरणात्मक पाठय से जोड</strong>: संक्षिप्त
                                        विवरण से विवरणात्मक विस्तृत विषय पढाया जा सकता है
                                        जैसे "Read More" एवं "Click Here" को पाठय में
                                        जोडना उदाहणार्थ यदि हम पी.डी.एफ. फाइल खोलें तो
                                        उसे विशिष्ट विवरणात्मक पाठय मे ले सकते है यदि नया
                                        विण्डो से जोडे तो वह वैसे ही विशिष्ट विवरण बतायेगा
                                        ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>टेबल</strong> <strong>हैडर</strong>: इस
                                        टेबल हेडर में पत्र व्यवहार खाने में प्रत्येक
                                        लार्इन में जुड सकता है उदाहरणार्थ यदि 30 लाइन और
                                        पांच खाने टेबल में हो तो उसे कर्सर का उपयोग कर
                                        पाना कठिन हो जाता है । जिसमें आंकडे का खाना को
                                        पहचानने में कठिनार्इ जो सकती है कि वह किस हेडर में
                                        है । एैसी सिथति एक सहायक प्रति हेडर स्क्रीन में
                                        देख सकते हैं जिसके पढने से किसी भी खाने का हेडर
                                        किसी खाने में मिल सकता है । तदानुसार उपयोग किया जा
                                        सकता है । विशेष रूप से उसे प्रत्येक टेबल में कार्य
                                        कराया जा सकता है एवं पता लगाया जा सकता है कि किस
                                        टेबल में क्या आंकडा प्रस्तुत है ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>हेडिंग</strong>: वेब पेज में यह व्यवस्था
                                        है कि सही हेडिंग एवं उपहेडिंग का उपयोग किया जा सके
                                        एवं उसे संरचना से पढा जा सके । एच.1 संकेत देता है
                                        कि वह मुख्य हेडिंग है जबकि एच.2 से उपहेंडिग का पता
                                        चलता है । इसके अतिरिक्त स्क्रीन में उपयोगकर्ता इसे
                                        पढ सकता है । इस वेबसाइट में छुपे हुये हेंडिंग को
                                        पढा जाकर स्क्रीन में बेहतर ढंग से देखा जा सकता है
                                        उदाहरण के लिये नेवीगेशन पेन के लिये छुपा हुआ
                                        हेंडिंग ग्लोबल नेवीगेशन के रूप में देखा जा सकता है
                                        ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>शीर्षक</strong>: उचित शीर्षक से यह आसान हो
                                        जाता है कि प्रत्येक वेब पेज में क्या विशिष्टता है
                                        जिसके मदद से हम सरलता से पेज में निहित लेख को पढ
                                        सकते हैं ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>पाठय का विकल्प</strong>: संक्षिप्त विवरण
                                        एक इमेज है जिसे उपयोगकर्ता को देखने में कठिनाइ
                                        होगी यदि आप ब्राउसर की मदद से पाठय में इमेज को
                                        डिस्प्ले करे तो आपको इमेज के बगैर विकल्प पाठय के
                                        रूप में मिलेगा ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>लेबल फार्म का स्पष्ट रूप</strong>: लेबल को
                                        जोडने से उसके कंट्रोल अनुसार टेक्सट बाक्स में
                                        रेडियो बटन का उपयोग करने एवं सूची देखकर हम लेबल
                                        फार्म कंट्रोल सहायक डिवाइस के उपयोग से कर सकते हैं
                                        ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>नेविगेशन मैकेनिज्म का कार्य</strong>:
                                        वेबसाइट में निशिचत रूप से नेवीगेशन मेकेनिज्म का
                                        लगातार कार्य कर सकते हैं ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>सूची का विस्तार एवं नष्ट करना</strong>:
                                        विषयों की सूची जो हमें प्राप्त है उसका विस्तार
                                        उसके उपविषय के रूप में किया जा सकता है एवं उसे
                                        छोटा किया जा सकता है जिसके लिये हमें की बोर्ड,
                                        माउस, स्क्रीन रीडर का उपयोग करना होगा । वेबसाइट के
                                        एैसे बहुत से वेब पेज है जिसकी सूची फैलाया एवं छोटा
                                        किया जा सकता है प्रत्येक सूची के सामाग्री में ‘+'
                                        चिन्ह का अर्थ है उसे फैलाया जावे । यह प्रतीक एक
                                        वैकलिपक पाठय के रूप में दिया गया है जिसे स्क्रीन
                                        रीडर द्वारा पहचान किया जाकर संबंधित प्रतीक का
                                        उपयोग करें इसके साथ अदृश्य मान की सिथति हो सकती है
                                        । ‘+' प्रतीक को किलक करने पर ‘–‘ प्रतीक में यदि वह
                                        परिवर्तित होता है तो विषय नष्ट हो सकता है पुन:
                                        वैकलिपक पाठय के रूप में इसी प्रकार से सूची में
                                        उपविषय दिखाया जाता रहा है । यदि ‘–‘ प्रतीक को किलक
                                        करे वो वह ‘+' रूप में परिवर्तित हो सकता है । पुन:
                                        वैकलिपक पाठय के रूप में उसे फिर से लिया जाकर उसे
                                        सूची में उपविषय के रूप में शामिल कर उसे शामिल किया
                                        जा सकता है । जिसे नष्ट करने से संबंधित हो सकता है
                                        ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>की</strong><strong>-</strong
                                        ><strong>बोर्ड</strong> <strong>सहायक</strong>:
                                        वेबसाइट में ब्राउसर की मदद से की बोर्ड का उपयोग
                                        किया जा सकता है जिसमें यदि हम शिफ्ट + टैब को दबाए
                                        ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>पाठय का आकार निश्चय करना</strong>: पाठय का
                                        आकार बेव पेज में बदला जा सकता है जिममें ब्राउसर या
                                        वैकलिपक लेख लगातार प्राप्त किया जा सकता है ।
                                      </li>
                                      <li style="text-align: justify" [ngStyle]="{
                                        'color': cms.colorfront.value,                           
                                        'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                      }">
                                        <strong>जावा स्क्रिप्ट का स्वतंत्र कार्य</strong>:
                                        वेब पेज सूचना एवं कार्य हेतु हम स्वतंत्र रूप से
                                        जावा स्क्रिप्ट का ब्राउसर के बगैर स्क्रिप्ट
                                        लेंगवेज के लिये कार्य कर सकते हैं ।
                                      </li>
                                    </ol>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      <strong>वैकलिपक प्राप्तियाँ</strong>
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      यदि आप स्क्रीन में लेखों को पढने में कठिनाइ पाते
                                      हैं ?
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      क्या दर्शित सूचनाए स्पष्ट रूप से देखने में नहीं आती
                                      है ?
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      यदि हा तो वैकलिपक पहुंच वेबसाइट के माध्यम से दी जा
                                      सकती है जिसमें स्क्रीन डिस्प्ले को नियंत्रण किया जा
                                      सकता है । यह विकल्प पाठय के आकार एवं कलर कार्यक्रम
                                      को बदलने में, स्पष्ट दिखायी देने एवं बेहतर पढने के
                                      लिये किया जा सकता है ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      <strong>पाठ्य सामग्री का आकार बदलना </strong>
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      पाठय के आकार को छोटे या बडे रूप, सामान्य आकार से
                                      बदला जा सकता है जिसके लिये तीन विकल्प आपको दिये गये
                                      है जिसमें पाठय के आकार को परिवर्तित कर पठनीय बनाया
                                      गया है निम्नानुसार है :
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      <strong>बृहत आकार</strong>: प्रदर्शित सूचना में बृहत
                                      फाण्ट आकार ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      <strong>बडा आकार</strong>: सामान्य आकार से अधिक
                                      फोण्ट साइज बडा आकार प्रदर्शित सूचना में दिखाया
                                      जाना ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      <strong>मध्यम आकार</strong>: फोण्ड साइज में
                                      सामान्य आकार जिसे डिफाल्ट साइज कहा जाता है
                                      प्रदर्शित सूचना में दिखाया जाना ।
                                    </p>
                                    <p style="text-align: justify; " [ngStyle]="{
                                      'color': cms.colorfront.value,                           
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }">
                                      किसी भी पेज के ऊपर पाठय आकार परिवर्तन हेतु आइकान
                                      टेक्सट साइज दिया गया है ।
                                    </p>
                                  </div>
                                </div>
                                <div class="entry-links" style=""></div>
                              </div>
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
          </div>
  </div>
</div>
