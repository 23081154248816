<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/otheractivity.png" alt="" style="height: 25px;width: 25px;">
                    {{'OTHERACTIVITY.Title' |
                    translate}}
                </div>
            </div>
            <div class="card-body">

                <table id="table1">
                    <ng-container *ngIf="commonservice.lang.value=='Hindi'">
                        <tr>
                            <th>क्र.सं</th>
                            <th>विवरण</th>
                    </ng-container>

                    <ng-container *ngIf="commonservice.lang.value=='English'">
                        <tr>
                            <th>S.No.</th>
                            <th>Description</th>
                        </tr>
                    </ng-container>


                    <ng-container *ngIf="commonservice.lang.value=='Hindi'">
                        <tr *ngFor="let d of data ; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{d.activities_hn}} </td>
                        </tr>

                    </ng-container>

                    <ng-container *ngIf="commonservice.lang.value=='English'">

                        <tr *ngFor="let d of data ; let i=index;">
                            <td>{{i+1}}</td>
                            <td>{{d.activities_en}} </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
        </div>
    </div>
</div>