import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-acessiblity-sttmnt',
  templateUrl: './acessiblity-sttmnt.component.html',
  styleUrls: ['./acessiblity-sttmnt.component.scss']
})
export class AcessiblitySttmntComponent implements OnInit {

  constructor(public cms: CommonService) { }

  ngOnInit(): void {
  }

}
