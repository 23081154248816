<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center"
                    [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/taskforce.png" alt="" style="height: 25px;width: 25px;"> &nbsp;
                    {{'TASKFORCE.Title' |
                    translate}}
                </div>
            </div>

        </div>

        <div id="cards_landscape_wrap-2">
            <div class="row">
                <div class="col-md-2" *ngFor="let t of data">
                    <div class="card-flyer">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(t.activityid)">
                            <div class="text-box">
                                <div class="image-box">
                                    <div class="float-element white_circle">
                                        <img src="{{rootUrl + t.activitypath}}" alt="" />
                                    </div>
                                </div>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{t.activitytitleen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{t.activitytitlehn}}
                                    </h5>

                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'" style="font-weight: normal;">
                                        {{t.activitydescen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'" style="font-weight: normal;">
                                        {{t.activitydeschn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                &nbsp;
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="desc" role="dialog">
    <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}"
                    *ngIf="commonservice.lang.value=='Hindi'">  {{this.activitydesc[0]?.activitytitlehn}}</h4>
                <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}"
                    *ngIf="commonservice.lang.value=='English'"> {{this.activitydesc[0]?.activitytitleen}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div style="text-align: center;">
                    <img class="image-responsive image_logo" src="{{rootUrl + this.activitydesc[0]?.activitypath}}" alt=""
                        style="width: 200px; height: 200px; ">
                </div>
                <br>
                <h1 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                    *ngIf="commonservice.lang.value=='Hindi'" style="font-weight: normal;">
                    <b> {{this.activitydesc[0]?.activitydeschn}} </b>
                </h1>

                <h1 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                    *ngIf="commonservice.lang.value=='English'" style="font-weight: normal;">
                    <b> {{this.activitydesc[0]?.activitydescen}} </b>
                </h1>
                
                <br>

                <!-- <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}" *ngIf="commonservice.lang.value=='Hindi'">
                    अध्यक्ष :   {{this.activitydesc[0]?.chairmanhn}} </h4>

                <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}" *ngIf="commonservice.lang.value=='English'">
                    Chairman : {{this.activitydesc[0]?.chairmanen}}</h4> -->
                <ng-container *ngFor="let t of activitydetails"> 
                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                        *ngIf="commonservice.lang.value=='Hindi'" style="font-weight: normal;">
                        {{t.activitydesc_hn}}
                        <a [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" style="cursor: pointer;"
                            target="_blank" href="{{rootUrl + t.activitypath}}">
                            <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                        </a>
                    </p>

                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                        *ngIf="commonservice.lang.value=='English'" style="font-weight: normal;">
                        {{t.activitydesc_en}}
                        <a [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" style="cursor: pointer;"
                            target="_blank" href="{{rootUrl + t.activitypath}}">
                            <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                        </a>
                    </p>

                </ng-container>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">{{'TEAMSPC.Close' |
                    translate}}</button>
            </div>
        </div>

    </div>
</div>