
<div class="my-5" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="card-header text-center" [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                    <img src="../../assets/images/logo/executives.png" alt="" style="height: 25px;width: 25px;">
                    {{'TEAMSPC.TeamSPCTitle' |
                    translate}}
                </div>
            </div>            
        </div>
        <div id="cards_landscape_wrap-2">
            <br>
            <div class="row">
                <!-- <div class="col-md-2" *ngFor="let t of data">
                    <div class="card-flyer">
                        <a class="read_more" data-toggle="modal" data-target="#desc"   (click)="details(t.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + t.imgpath}}" alt="" />
                                </div>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{t.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">                                   
                                        {{t.namehn}} 
                                    </h5>                                
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{t.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{t.designationhn}}
                                    </p>                                    
                                </div>
                            </div>
                        </a>
                    </div>
                </div> -->
                <div class="col-md-12">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc"   (click)="details(data[0]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[0]?.imgpath}}" alt=""  style="height: 150px;width: 150px;"/>
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                        *ngIf="commonservice.lang.value=='English'">
                                                        {{data[0]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                        *ngIf="commonservice.lang.value=='Hindi'">                                   
                                                        {{data[0]?.namehn}} 
                                    </h5>                                
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                        *ngIf="commonservice.lang.value=='English'">
                                                        {{data[0]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                        *ngIf="commonservice.lang.value=='Hindi'">
                                                        {{data[0]?.designationhn}}
                                    </p>                                    
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                &nbsp;
            </div>
            <div class="row">     
                <div class="col-md-1"></div>
                <div class="col-md-5">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(data[1]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[1]?.imgpath}}" alt="" style="height: 150px;width: 150px;" />
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[1]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[1]?.namehn}}
                                    </h5>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[1]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[1]?.designationhn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-5">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(data[2]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[2]?.imgpath}}" alt="" style="height: 150px;width: 150px;" />
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[2]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[2]?.namehn}}
                                    </h5>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[2]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[2]?.designationhn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                &nbsp;
            </div> 
            <br>
            <div class="row">
                <div class="col-md-4"></div>
                <!-- <div class="col-md-3">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(data[4]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[4]?.imgpath}}" alt="" style="height: 150px;width: 150px;" />
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[4]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[4]?.namehn}}
                                    </h5>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[4]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[4]?.designationhn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div> -->
                <div class="col-md-4">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(data[3]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[3]?.imgpath}}" alt="" style="height: 150px;width: 150px;" />
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[3]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[3]?.namehn}}
                                    </h5>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[3]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[3]?.designationhn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
                <div class="col-md-4"></div>
                <!-- <div class="col-md-4">
                    <div class="">
                        <a class="read_more" data-toggle="modal" data-target="#desc" (click)="details(data[5]?.id)">
                            <div class="text-box">
                                <div class="image-box">
                                    <img src="{{rootUrl + data[5]?.imgpath}}" alt="" style="height: 150px;width: 150px;" />
                                </div>
                                <br>
                                <div class="text-container">
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[5]?.nameen}}
                                    </h5>
                                    <h5 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[5]?.namehn}}
                                    </h5>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='English'">
                                        {{data[5]?.designationen}}
                                    </p>
                                    <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                        *ngIf="commonservice.lang.value=='Hindi'">
                                        {{data[5]?.designationhn}}
                                    </p>
                                </div>
                            </div>
                        </a>
                    </div>
                </div> -->
                &nbsp;
            </div>
        </div>      
    </div>
</div>

<div class="modal fade" id="desc" role="dialog">
    <div class="modal-dialog modal-lg">
        <!-- Modal content-->
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}"
                                        *ngIf="commonservice.lang.value=='Hindi'"> {{this.teamdesc[0]?.designationhn}}</h4>
                <h4 class="modal-title" [ngStyle]="{'color': cms.colorfront.value}"
                    *ngIf="commonservice.lang.value=='English'"> {{this.teamdesc[0]?.designationen}}</h4>
                <button type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div style="text-align: center;">
                    <img class="image-responsive image_logo"
                        src="{{rootUrl + this.teamdesc[0]?.imgpath}}" alt="" style="width: 200px; height: 200px; ">
                </div>
                <br>
                <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                    *ngIf="commonservice.lang.value=='Hindi'" class="text-justify">
                <span [innerHtml]="this.teamdesc[0]?.deschn"></span></p>
                
                <p [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                    *ngIf="commonservice.lang.value=='English'" class="text-justify">
                    <span [innerHtml]="this.teamdesc[0]?.descen" ></span></p>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" data-dismiss="modal">{{'TEAMSPC.Close' |
                    translate}}</button>
            </div>
        </div>

    </div>
</div>

