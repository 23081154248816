<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <div class="text-white">
                    <div class="card-header text-center"
                        [ngStyle]="{'color': '#000000', 'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/trophy.png" alt="" style="height: 25px;width: 25px;">
                        {{'ABOUTVC.AboutVc' |
                        translate}}
                    </div>
                    <div class="card-body">
                        <div class="row ">
                            <div class="col-md-12">
                                <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}"
                                    class="text-justify">
                                    {{'ABOUTVC.Details1'|
                                    translate}}</p>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>