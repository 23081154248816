import { Component, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from 'src/app/services/common.service';
import { createHostListener } from '@angular/compiler/src/core';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Component({
  selector: 'app-full',
  templateUrl: './full.component.html',
  styleUrls: ['./full.component.scss']
})

export class FullComponent implements OnInit {
  skipLinkPath: string;
  public lastEvent: any;
  rootUrl = environment.rootUrl;
  constructor(public translate: TranslateService, public cms: CommonService, private router: Router) 
  {
    translate.addLangs(['English', 'Hindi']);
    translate.setDefaultLang('Hindi');
    const browserLang = translate.getBrowserLang();
    translate.use(browserLang.match(/|Hindi/) ? browserLang : 'Hindi');    
  }
  
  ngOnInit(): void { 
    this.useLanguage('Hindi');
    this.skipLinkPath = `${this.router.url}#main-content`;
    this.skipLinkPath = `${this.router.url}#nav-content`;
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.langChange(language);
  }

  langChange(la: any) {
    this.cms.changeLanguage(la);
  }

  increase() {
    this.cms.fontsize.next(16);
    this.cms.fontHeadersize.next(22);
    this.cms.fontSmallsize.next(12);
    
  }

  decrease() {
    this.cms.fontsize.next(10);
    this.cms.fontHeadersize.next(14);
    this.cms.fontSmallsize.next(8);
  }

  normal() {
    this.cms.fontsize.next(14);
    this.cms.fontHeadersize.next(16);
    this.cms.fontSmallsize.next(10);
  }

  home() {

    window.location.reload();

    // this.cms.colorcode.next('#ffffff'); //Color
    // this.cms.colorfront.next('#164863');//backgroundcolor
    // this.cms.colorbackcode.next('linear-gradient(to right, rgb(252, 250, 250), #05074f)');//backgroundcolor);
    // this.cms.colorfrontblack.next('#174c69'); // Body Color background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
  }

  // public colorcode: BehaviorSubject<string> = new BehaviorSubject<string>('#ffffff');//heading-color
  // public colorbackcode: BehaviorSubject<string> = new BehaviorSubject<string>('linear-gradient(to right, rgb(252, 250, 250), #05074f)');//backgroundcolor
  // public colorfront: BehaviorSubject<string> = new BehaviorSubject<string>('#164863');//cardheader-color
  // public colorfrontblack: BehaviorSubject<string> = new BehaviorSubject<string>('#427D9D');


  dark() {
    this.cms.colorcode.next('#ff0'); //Color
    this.cms.colorcodeblack.next('#ff0'); //Color
    this.cms.colorfront.next('#000000'); //Background Color
    this.cms.colorbackcode.next('#000000');
    this.cms.colorfrontblack.next('#000000'); // Body Color background: linear-gradient(40deg,#2096ff,#05ffa3) !important;
  }

  
  refresh() {
    window.location.reload();
  }

}
