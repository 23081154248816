

<div class="page-breadcrumb">
    <div class="row">
        <div class="col-12 align-self-center">
            <h5 style="margin-bottom:0px; font-family: 'Times New Roman', Times, serif;" class="page-title"  [ngStyle]="{'font-size': cms.fontsize.value +'px', 'color': cms.colorfront.value}">
                <div *ngIf="cms.lang.value=='English'">
                <div  *ngIf="pageInfo?.title != 'Official Website of Rajya Niti Aayog'; else templateName">
                    <a  *ngFor="let item of pageInfo?.urls" [routerLink]="item.url"  [ngStyle]="{'color': cms.colorcodeblack.value}">{{item.title}}&nbsp;/</a> <span [ngStyle]="{'color': cms.colorcodeblack.value}">{{pageInfo?.title}}</span>
                </div>
                <ng-template #templateName>
                    
                </ng-template>
            </div>
                <div *ngIf="cms.lang.value=='Hindi'">
                <div *ngIf="pageInfo?.titlehn != 'राज्य नीति आयोग की आधिकारिक वेबसाइट'; else templateName1">
                    <a  *ngFor="let item of pageInfo?.urls" [routerLink]="item.url"  [ngStyle]="{'color': cms.colorcodeblack.value}">{{item.titlehn}}&nbsp;/</a><span  [ngStyle]="{'color': cms.colorcodeblack.value}">{{pageInfo?.titlehn}}</span>
                </div>
                <ng-template #templateName1>
                    
                </ng-template>
            </div>
            </h5>
        </div>
    </div>
</div>