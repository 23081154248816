<div class="my-3" id="b-homedb">
  <div class="container-fluid">
    <div class="row">
      <div class="col-lg-12">
        <div class="card-header text-center"
          [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
          <img src="../../assets/images/logo/contactus.png" alt="" style="height: 25px;width: 25px;">
          {{'FOOTER.ContactUs' |
          translate}}
        </div>
      </div>
      <div class="card-body">
        <div *ngIf="cms.lang.value == 'English'" id="content">
          <div class="container-xxl">
            <div class="container">            
              <div class="row g-4">
                <div class="col-md-7">
                  <div class="pt-3">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.6834878276804!2d81.78811497365784!3d21.164990733088725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28c6ba49f543db%3A0x5355b3dbcbf93f72!2sYojana%20Aayog!5e0!3m2!1sen!2sin!4v1700812568414!5m2!1sen!2sin"
                      width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"></iframe>
          
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="pt-3">
                    <div class="card">
                      <img src="../../assets/images/logo/contactus.png" alt="" style="height: 25px;width: 25px;">
                      <strong style="font-family: georgia, serif; " [ngStyle]="{
                                                          color: cms.colorfront.value,
                                                          'font-size': cms.fontsize.value + 'px'
                                                        }"><b>Office Address: </b> </strong>
                    </div>
          
                    <div class="journal-content-article" id="article_10153_10179_30472_1.8">
                      <p style="
                                                      margin-top: 0.5em;
                                                      margin-bottom: 0.5em;
                                                      color: rgb(50, 50, 50);
                                                      font-family: Baskerville, Georgia, Palatino,
                                                        'Palatino Linotype', 'Book Antiqua',
                                                        'URW Palladio L', serif;
                                                      font-size: 13px;
                                                      line-height: 18px;
                                                      text-align: justify;
                                                    ">
                        <strong style="font-family: georgia, serif; " [ngStyle]="{
                                                        color: cms.colorfront.value,
                                                        'font-size': cms.fontsize.value + 'px'
                                                      }">&nbsp; &nbsp; Address: </strong>
                      </p>
                      <p style="
                                                      margin-top: 0.5em;
                                                      margin-bottom: 0.5em;
                                                      color: rgb(50, 50, 50);
                                                      font-family: Baskerville, Georgia, Palatino,
                                                        'Palatino Linotype', 'Book Antiqua',
                                                        'URW Palladio L', serif;
                                                      font-size: 13px;
                                                      line-height: 18px;
                                                      text-align: justify;
                                                    ">
                        <span style="margin: 0px; padding: 0px; "><span style="
                                                          margin: 0px;
                                                          padding: 0px;
                                                          font-family: georgia, serif;
                                                        " [ngStyle]="{
                                                          color: cms.colorfront.value,
                                                          'font-size': cms.fontsize.value + 'px'
                                                        }">&nbsp; &nbsp; Chhattisgarh, Rajya NITI Aayog<br />
                            &nbsp; &nbsp; North Block, Sector-19, Near Mantralayas,<br />
                            &nbsp; &nbsp; Nava Raipur Atal Nagar, Chhattisgarh,<br />
                            &nbsp;&nbsp;&nbsp; Raipur-492001<br />
                            &nbsp;&nbsp;&nbsp; Phone: 0771-2511223, 0771-2511218<br />
                            &nbsp; &nbsp; E-mail:&nbsp;<a href="ms-niti[at]cg.gov.in" style="
                                                            margin: 0px;
                                                            padding: 0px;
                                                            text-decoration: none;
                                                            color: rgb(42, 148, 0);
                                                          " [ngStyle]="{
                                                            color: cms.colorfront.value,
                                                            'font-size': cms.fontsize.value + 'px'
                                                          }">ms-niti[at]cg.gov.in</a></span></span>
                      </p>
          
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>    
        </div>

        <div *ngIf="cms.lang.value == 'Hindi'" id="content">
          <div class="container-xxl">
            <div class="container">            
              <div class="row g-4">
                <div class="col-md-7">
                  <div class="pt-3">
                    <iframe
                      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.6834878276804!2d81.78811497365784!3d21.164990733088725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28c6ba49f543db%3A0x5355b3dbcbf93f72!2sYojana%20Aayog!5e0!3m2!1sen!2sin!4v1700812568414!5m2!1sen!2sin"
                      width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy"
                      referrerpolicy="no-referrer-when-downgrade"></iframe>
        
                  </div>
                </div>
                <div class="col-md-5">
                  <div class="pt-3">
                    <div class="card">
                      <img src="../../assets/images/logo/contactus.png" alt="" style="height: 25px;width: 25px;">
                      <strong style="font-family: 'Times New Roman', Times, serif; " [ngStyle]="{
                                                            color: cms.colorfront.value,
                                                            'font-size': cms.fontsize.value + 'px'
                                                          }"><b>कार्यालय पता: </b> </strong>
                    </div>
        
                    <div class="journal-content-article" id="article_10153_10179_30472_1.8">
                      <p style="
                                                        margin-top: 0.5em;
                                                        margin-bottom: 0.5em;
                                                        color: rgb(50, 50, 50);
                                                        font-family: Baskerville, Georgia, Palatino,
                                                          'Palatino Linotype', 'Book Antiqua',
                                                          'URW Palladio L', serif;
                                                        font-size: 13px;
                                                        line-height: 18px;
                                                        text-align: justify;
                                                      ">
                        <strong style="font-family: georgia, serif; " [ngStyle]="{
                                                          color: cms.colorcodeblack.value,
                                                          'font-size': cms.fontsize.value + 'px'
                                                        }">&nbsp; &nbsp;पता: </strong>
                      </p>
                      <p style="
                                                        margin-top: 0.5em;
                                                        margin-bottom: 0.5em;
                                                        color: rgb(50, 50, 50);
                                                        font-family: Baskerville, Georgia, Palatino,
                                                          'Palatino Linotype', 'Book Antiqua',
                                                          'URW Palladio L', serif;
                                                        font-size: 13px;
                                                        line-height: 18px;
                                                        text-align: justify;
                                                      ">
                        <span style="margin: 0px; padding: 0px; "><span style="
                                                            margin: 0px;
                                                            padding: 0px;
                                                            font-family: georgia, serif;
                                                          " [ngStyle]="{
                                                            color: cms.colorcodeblack.value,
                                                            'font-size': cms.fontsize.value + 'px'
                                                          }">&nbsp; &nbsp; छत्तीसगढ़, राज्य नीति आयोग<br />
                            &nbsp; &nbsp; नॉर्थ ब्लॉक, सेक्टर-19, मंत्रालय के पास,<br />
                            &nbsp; &nbsp; नवा रायपुर अटल नगर, छत्तीसगढ़,<br />
                            &nbsp;&nbsp;&nbsp; रायपुर-492001<br />
                            &nbsp;&nbsp;&nbsp; दू. 0771-2511223, 0771-2511218<br />
                            &nbsp; &nbsp; E-mail:&nbsp;<a href="ms-niti[at]cg.gov.in" style="
                                                              margin: 0px;
                                                              padding: 0px;
                                                              text-decoration: none;
                                                              color: rgb(42, 148, 0);
                                                            " [ngStyle]="{
                                                              color: cms.colorcodeblack.value,
                                                              'font-size': cms.fontsize.value + 'px'
                                                            }">ms-niti[at]cg.gov.in</a></span></span>
                      </p>
                      <!-- ms[dot]cgspc[at]gov.in -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



