import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-viksitcg',
  templateUrl: './viksitcg.component.html',
  styleUrls: ['./viksitcg.component.scss']
})
export class ViksitcgComponent implements OnInit {

  public id: any;
  constructor(private fb: FormBuilder, public commonservice: CommonService,
    public cms: CommonService, private route: ActivatedRoute) {

  }

  ngOnInit(): void {
    //window.open('https://sdgspc.cg.gov.in/bestpractice/#/home', '_blank');
  }

}
