import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from './services/common.service';
import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  fontsize$: any;
  lastEvent: any;
  constructor(public translate: TranslateService, public cms: CommonService) { }
}

//   translate.addLangs(['English', 'hi']);
//   translate.setDefaultLang('English');

//   const browserLang = translate.getBrowserLang();
//   translate.use(browserLang.match(/en|hi/) ? browserLang : 'English');
// }
// langChange(la: any) {
//   this.cms.changeLanguage(la);
// }

