import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root'
})
export class CommonService {

    public lang = new BehaviorSubject<string>('Hindi');
    public API_URL = 'https://dprcg.gov.in/api/get-news-list';
    

    token: any = 'k9Jl67Hty54mlj00Poingvbhrgff67hjh565utrdeqowoei';
    header = new HttpHeaders().set('Authorization', [
       this.token,
    ]);
    options = { headers: this.header };

    //#92618E #4A2849
  
    public colorcode: BehaviorSubject<string> = new BehaviorSubject<string>('#ffffff');//heading-color
    public colorcodeblack: BehaviorSubject<string> = new BehaviorSubject<string>('#000000');//heading-color
    public colorbackcode: BehaviorSubject<string> = new BehaviorSubject<string>('linear-gradient(to right, rgb(252, 250, 250), #05074f)');//backgroundcolor
    public colorfront: BehaviorSubject<string> = new BehaviorSubject<string>('#001944');//cardheader-color
    public colorfrontblack: BehaviorSubject<string> = new BehaviorSubject<string>('#001944');
    

    public fontsize: BehaviorSubject<number> = new BehaviorSubject<number>(14);
    public fontHeadersize: BehaviorSubject<number> = new BehaviorSubject<number>(22);
    public fontSmallsize: BehaviorSubject<number> = new BehaviorSubject<number>(10);

    constructor(private authService: AuthService, private http: HttpClient) { }

    changeLanguage(la: any) {
        this.lang.next(la);
    }

    getfontsizenormal(): BehaviorSubject<number> {
        return this.fontsize;
    }
    getfontsizeheader(): BehaviorSubject<number> {
        return this.fontHeadersize;
    }
    getfontsizesmall(): BehaviorSubject<number> {
        return this.fontSmallsize;
    }

    getcolorcode(): BehaviorSubject<string> {
        return this.colorcode;
    }

    getcolorbackcode(): BehaviorSubject<string> {
        return this.colorbackcode;
    }

    getcolorfront(): BehaviorSubject<string> {
        return this.colorfront;
    }

    getcolorfrontblack(): BehaviorSubject<string> {
        return this.colorfrontblack;
    }

    ///////////////////////////////////////////////////////////////////////

    insert(data: any, table_name: any) {
        const url = `${environment.rootUrl + 'insert'}/${table_name}`;
        return this.http.post(url, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    ////////////////////////////////////////////////////////////////

    createdept(data: any, table_name: any) {
        const url = `${environment.rootUrl + 'insertdept'}/${table_name}`;
        return this.http.post(url, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    ////////////////////////////////////////////////////////////////////

    createdeptoperator(data: any) {
        const url = `${environment.rootUrl + 'insertoperator'}`;
        return this.http.post(url, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    //   update(funtionName: any, data: any) {
    //     return this.http.put(environment.rootUrl + funtionName, data).pipe(tap(res => { res }),
    //         catchError(e => {
    //             throw new Error(e);
    //         })
    //     );
    // }

    ////////////////////////////////////////////////////////

    update(data: any, table_name: any) {
        const url = `${environment.rootUrl + 'update'}/${table_name}`;
        return this.http.put(url, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    //////////////////////////////////////////////////////////////


    updatedata(data: any, table_name: any) {
        const url = `${environment.rootUrl + 'updatedata'}/${table_name}`;
        return this.http.put(url, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    


    ///////////////////////////////////////////////////////////

    updateFuction(funtionName: any, data: any) {
        return this.http.put(environment.rootUrl + funtionName, data).pipe(tap(res => { res }),
            catchError(e => {
                throw new Error(e);
            })
        );
    }

    /////////////////////////////////////////////////////////

    // upload(funtionName: any, data: any) {
    //     return this.http.post(environment.rootUrl + funtionName, data).pipe(tap(res => { res }),
    //         catchError(e => {

    //             throw new Error(e);
    //         })
    //     );
    // }

    // remove(funtionName: any) {
    //     return this.http.delete(environment.rootUrl + funtionName).pipe(tap(res => { res }),
    //         catchError(e => {
    //             throw new Error(e);
    //         })
    //     );
    // }

    getFunction(functionName: any) {
        return this.http.get(environment.rootUrl + functionName).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }

    getCntFunction(functionName: any, dept_id: any) {
        return this.http.get(environment.rootUrl + functionName + '/' + dept_id).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }

    getNListFunction(functionName: any, dept_id: any) {
        return this.http.get(environment.rootUrl + functionName +  '/' + dept_id).pipe(tap(res => res), catchError(e => {
            throw new Error(e);
        }));
    }

// 

getRange(functionName: any){
    return this.http.get(environment.rootUrl + functionName).pipe(tap(res => res), catchError(e => {

        throw new Error(e);
    }));
}

    getDistrict(functionName: any, range_id : any){
        return this.http.get(environment.rootUrl + functionName + '/' + range_id).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }

    getAwardLists(functionName: any) {
        return this.http.get(environment.rootUrl + functionName).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }
    getMenuLists(functionName: any) {
        return this.http.get(environment.rootUrl + functionName).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }
    
    getVisitorCount(functionName: any): Observable<any> {
        return this.http.get(environment.rootUrl + functionName).pipe(tap(res => res), catchError(e => {

            throw new Error(e);
        }));
    }


    // for multiple params
    paramFunction(functionName: any, params: any) {
        const url = environment.rootUrl + functionName + '/' + params;
        return this.http.get(url).pipe(tap(res => res), catchError(e => {
            throw new Error(e);
        }));
    }

    noticListFunction(functionName: any, dept_id: any,  status: any) {
        const url = environment.rootUrl + functionName + '/' + dept_id + '/' + status;
       // alert(url);
        return this.http.get(url).pipe(tap(res => res), catchError(e => {
            throw new Error(e);
        }));
    }



    public getDprNews(): Observable<any> {
       // alert("hi DPR services"); 
        return this.http.get(this.API_URL, this.options).pipe(tap(res => res));
      }

}
