<div class="row">
<div class="col-sm-12 col-md-12 col-lg-12">
<footer class="mainfooter" role="contentinfo" >
  <div class="footer-middle" [ngStyle]="{'color': cms.colorcode.value, 'background-color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}">
    <div class="container-fluid">
      <div class="row">
        <ul class="card-body cgExtLinks">
          <li class="cgExtLinksImages3">
            <a
              href="http://www.cg.nic.in"
              target="_blank"
              title="राष्ट्रीय सूचना-विज्ञान केन्द्र"
              ><img
                alt="राष्ट्रीय सूचना-विज्ञान केन्द्र(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/NIC.jpg"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="http://tourism.cg.gov.in/"
              target="_blank"
              title="छत्तीसगढ़ पर्यटन पोर्टल"
              ><img
                alt="छत्तीसगढ़ पर्यटन पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/cgtourism.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="https://www.digitalindia.gov.in/"
              target="_blank"
              title="डिजिटल इंडिया"
              ><img
                alt="डिजिटल इंडिया(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/digitalIndia.jpg"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="https://edistrict.cgstate.gov.in/"
              target="_blank"
              title="e-District"
              ><img
                alt="चॉइस पोर्टल (बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/edistrictn.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages1">
            <a
              href="http://india.gov.in/hi/"
              target="_blank"
              title="भारत का राष्ट्रीय पोर्टल"
              ><img
                alt="भारत का राष्ट्रीय पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/indiaportal.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages4">
            <a
              href="https://cgpolice.gov.in/"
              target="_blank"
              title="छत्तीसगढ़ पुलिस पोर्टल"
              ><img
                alt="छत्तीसगढ़ पुलिस पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/cg-police.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="http://dprcg.gov.in/"
              target="_blank"
              title="पब्लिक रिलेशन पोर्टल"
              ><img
                alt="पब्लिक रिलेशन पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/dprcg.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="http://www.chips.gov.in/"
              target="_blank"
              title="चिप्स पोर्टल"
              ><img
                alt="चिप्स पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/chips_logo.jpg"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages2">
            <a
              href="https://negd.gov.in/"
              target="_blank"
              title="राष्ट्रीय ई - शासन पोर्टल"
              ><img
                alt="राष्ट्रीय ई - शासन पोर्टल(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/neg.png"
                class="footer_img"
            /></a>
          </li>
          <li class="cgExtLinksImages">
            <a
              href="https://gad.cg.gov.in/ias/"
              target="_blank"
              title="आई.ए.एस. ऑनलाइन"
              ><img
                alt="आई.ए.एस. ऑनलाइन(बाहरी वेबसाइट जो एक नई विंडों में खुलती हैं)"
                src="../../../assets/images/footer/IAS.jpg"
                class="footer_img"
            /></a>
          </li>
        </ul>
      </div>

      <div class="copy"></div>

      <div class="row">
        <div class="col-md-3 col-sm-6">
          
          <!-- <div class="footer-pad">
            <img
              src="assets/images/logo/Chips.jpg"
              alt=""
              style="height: 50px"
            />
            <p style="font-weight: bold;" [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.FooterData" | translate }}</p>
          </div> -->
        </div>
        <div class="col-md-3 col-sm-6">
          
          <!-- <div class="footer-pad" >
            <h3 [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.HelplineNo" | translate }}</h3>
            <ul class="list-unstyled" >
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline1" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline2" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline3" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline4" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline5" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline6" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline7" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline8" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline9" | translate }}</a></li>
              <li><a [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Helpline10" | translate }}</a></li>
            </ul>
          </div> -->
        </div>
        <div class="col-md-3 col-sm-6">
          
          
        </div>
        <div class="col-md-3" >
          <!-- <h3 [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.FollowUs" | translate }}</h3>
          <ul class="social-network social-circle">
            <li>
              <a
                href="https://www.facebook.com/CHiPSCgGov/"
                class="icoFacebook"
                title="Facebook"
                ><i class="fa fa-facebook"></i
              ></a>
            </li>
            <li>
              <a
                href="https://twitter.com/chipscggov?lang=en"
                class="icoTwitter"
                title="Twitter"
                ><i class="fa fa-twitter"></i
              ></a>
            </li>
          </ul> -->
        </div>
      </div>
      <div class="row">
        <div class="col-sm-4">

        </div>
        <div class="col-sm-4">
          <div class="text-center">
          <p>
            <a href="https://jigsaw.w3.org/css-validator/check/referer">
              <img style="border:0;width:88px;height:31px"
                  src="https://jigsaw.w3.org/css-validator/images/vcss-blue"
                  alt="Valid CSS!" />
          </a>
          <a href="https://validator.w3.org/check?uri=referer" target="_blank">
            <img src="https://www.w3.org/Icons/valid-xhtml10" 
            alt="Valid XHTML 1.0 Transitional" 
            height="31" width="88"></a>
        </p>
       
    </div>
        </div>
        <div class="col-sm-4"> 

        </div>
      </div>
      <div id="footer">
        <!-- <a href="#" title="सहायता" target="_blank"
          ><img
            src="../../../assets/images/footer/helpline_new.png"
            alt="Help Line"
            
            style="
               background:white; 
              float: left;
              position: relative;
              left: 1em;
              margin-top: -1em;
            "
        /></a> -->
        <ul style="text-align: center;" >
          <li class="first">
            <a [routerLink]="['/copyright']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.CopyrightF" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/tnc']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.TermsnCondition" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/feedback']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Feedback" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/policy']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.Policy" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/screen-read']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.ScreanRA" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/acces-statment']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.AccStat" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/site-map']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.SiteMap" | translate }}</span></a
            >
          </li>
          <li>
            <a [routerLink]="['/contact-us']"
              ><span [ngStyle]="{'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">{{ "FOOTER.ContactUs" | translate }}</span></a
            >
          </li>
        </ul>
      </div>
      <div class="bottom_box" style="text-align: center;" [ngStyle]="{'font-size': cms.fontsize.value +'px'}">
        <ul>
          <li class="servertime">
            <span>{{ "FOOTER.Today" | translate }} : </span
            ><span id="serverTime"> {{now | date:'EEEE, MMMM d, y, h:mm:ss a' | date:'medium':'IST'}}</span>
          </li>
          <li class="countportlet">
            <div
              id="p_p_id_VisitorsCount_WAR_cgssdgportlet_INSTANCE_AccmXu70YbN5_"
              class="portlet-boundary portlet-boundary_VisitorsCount_WAR_cgssdgportlet_ portlet-static portlet-static-end Visitors Count-portlet"
            >
              <span
                id="p_VisitorsCount_WAR_cgssdgportlet_INSTANCE_AccmXu70YbN5"
              ></span>
              <div class="portlet-body">
                <div class="portlet-borderless-container">
                  <div class="portlet-body">
                    <div  class="visit">
                      <p  class="visitCount">
                       | {{ "FOOTER.Visitors" | translate }} :  <span class="count">{{visitor}}</span>                       
                      </p>                      
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>
          </li>
          <li class="print_home">
            <a href="#" title="यह पेज प्रिंट करें" onclick="window.print()"
              ><i class="fa fa-print" aria-hidden="true"></i>
              <span class="read" [ngStyle]="{'font-size': cms.fontsize.value +'px'}"> {{ "FOOTER.Print" | translate }}</span></a
            >
          </li>
        </ul>
        <ul>
          <li class="lastModified" style="width: auto;">
            {{ "FOOTER.LastModiDate" | translate }} :<span id="modifiedDate"
              > {{dateYesterday | date:'EEEE, MMMM d, y, h:mm:ss a' | date:'medium':'IST'}}</span
            >
          </li>
         
        </ul>
        <ul>
          <li class="" style="width: auto;">
            {{ "FOOTER.FooterData1" | translate }}
          </li>
        </ul>
        <ul>
          <li class="lastModified" style="width: auto;">
            {{ "FOOTER.FooterData" | translate }}
          </li>
        </ul>
        
      </div>
      <div class="row">
        <div class="col-md-12 copy">
          <p [ngStyle]="{'font-size': cms.fontsize.value +'px'}" class="text-center">{{ "FOOTER.Copyright" | translate }}</p>
        </div>
      </div>
    </div>
  </div>
</footer>
</div>
</div>