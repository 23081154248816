import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-taskforce',
  templateUrl: './taskforce.component.html',
  styleUrls: ['./taskforce.component.scss']
})
export class TaskforceComponent implements OnInit {

  public activitydetails: any = [];
  public data: any = [];
  public activitydesc: any = [];
  rootUrl = environment.rootUrl;

  constructor(private http: HttpClient, private dp: DatePipe, public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {
    this.getSPCTeam();
  }

  getSPCTeam() {
    this.commonservice.getFunction('taskforce').subscribe(res => {
      this.data = res;
    }
    )
  };


  details(id: any) {
    this.commonservice.paramFunction('abouttaskforce', id).subscribe(res => {
      this.activitydesc = res; 
      this.commonservice.paramFunction('detailstask', id).subscribe(res => {
        this.activitydetails = res; 
      });     
    });
  }

}
