import { Component, Inject, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';
import { DOCUMENT } from '@angular/common';
import { OwlOptions } from 'ngx-owl-carousel-o';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})

export class HomeComponent implements OnInit {

  public now: Date = new Date();

  public schemes: any = [];
  public noticeboard: any = []; 
  public research: any = []; public publication: any = []; public reports: any = []; public others: any = [];
  public currentdate: any;
  public yesterday: any;
  public dept_id: any;
  public deptdata: any = [];
  public rootUrl = environment.rootUrl;
  public gallery: any = [];
  public carousel: any = [    
  ]
  slides: any[] = new Array(3).fill({id: -1, src: '', title: '', subtitle: ''});

  constructor(private http: HttpClient, public dp: DatePipe, public commonservice: CommonService, public cms: CommonService,
    @Inject(DOCUMENT) private document: Document) { }

  ngOnInit(): void {
  
    this.dept_id = 1;
    this.getDeptData(this.dept_id);
    this.getDeptbanner(this.dept_id);
    this.getDeptgallery(this.dept_id);
    this.getNoticeboard();
    this.getReports();
    this.getPublication();
    this.getResearch();
    this.currentdate = this.dp.transform(new Date().toISOString(), 'dd/MM/yyyy');
    this.yesterday = this.dp.transform((d => new Date(d.setDate(d.getDate() - 1)))(new Date).toISOString(), 'dd/MM/yyyy');
    this.getScheme();   
    this.getOthers();    

  }

  scrollToTop(): void {
    // scroll to the top of the body
    return this.document.body.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
      inline: 'start'
    });
  }

  getDeptData(dept_id: any) {
    this.commonservice.paramFunction('dept', dept_id).subscribe(res => {
      this.deptdata = res;
    });
  }

  getScheme() {
    this.commonservice.getFunction('scheme').subscribe(res => {
      this.schemes = res;
    });
  }

  getReports() {
    this.commonservice.getFunction('reports').subscribe(res => {
      this.reports = res;
    }); 
  }

  getPublication() {
    this.commonservice.getFunction('publications').subscribe(res => {
      this.publication = res;
    });
  }

  getNoticeboard() {
    this.commonservice.getFunction('noticeboard').subscribe(res => {
      this.noticeboard = res;
    });
  }

  getResearch() {
    this.commonservice.getFunction('research').subscribe(res => {
      this.research = res;
    });
  }

  getOthers() {
    this.commonservice.getFunction('others').subscribe(res => {
      this.others = res;
    });
  }

  //////////////////////////////////////////////////////////

  hitAnchor1(e) {
    if (this.cms.lang.value == 'Hindi')
    {
      alert("आपको किसी बाहरी वेबसाइट पर पुनर्निर्देशित किया जा रहा है|");
    }
    if (this.cms.lang.value == 'English') 
    {
      alert("You are being redirected to an external website.");
    }   
  }
  //////////////////////////////////////////////
 

  getDeptbanner(dept_id: any) {
    this.commonservice.paramFunction('banner', dept_id).subscribe(res => {
      this.carousel = res;
    });
  }

  getDeptgallery(dept_id: any) {
    this.commonservice.paramFunction('picgallery', dept_id).subscribe(res => {
      this.gallery = res;
    });
  }

  customOptions: OwlOptions = {
    loop: true,
    autoplay: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    dots: false,
    navSpeed: 700,
    navText: ['', ''],
    items: 4,
    margin: 10,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    responsive: {
      0: {
        items: 1
      },
      400: {
        items: 2
      },
      740: {
        items: 4
      }
    },
    nav: true
  }


  
}
