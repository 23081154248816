<div class="my-3" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">
                <mat-card>
                    <div class="card-header text-center"
                        [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/noticeboard.png" alt="" style="height: 25px;width: 25px;">
                        {{'NOTICEBOARD.NoticeBoard' | translate}}
                    </div>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label>खोजें/Search</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                        </mat-form-field>
                
                        <div class="mat-elevation-z8" style="overflow: auto">
                            <table class="table table-bordered" mat-table [dataSource]="dataSource" matSort>                
                                <!-- ID Column -->
                                <ng-container matColumnDef="sn">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'NOTICEBOARD.NBSNo' | translate}} </th>
                                    <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" mat-cell
                                        *matCellDef="let row"> {{row.sn}} </td>
                                </ng-container>
                
                                <ng-container matColumnDef="linkname">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'NOTICEBOARD.NBTitle' | translate}} 
                                    </th>
                                    <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" mat-cell
                                        *matCellDef="let row"> {{row.linknamehn}} <br> {{row.linknameen}}
                                        <ng-container *ngIf="row.status == 'N'">
                                            <span class="blink">New</span>
                                        </ng-container>
                                    </td>
                                </ng-container>
                
                                <!-- Progress Column -->
                                <ng-container matColumnDef="issuedate">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'NOTICEBOARD.NBStDate' | translate}}</th>
                                    <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" mat-cell
                                        *matCellDef="let row"> {{row.issuedate | date: 'dd/MM/yyyy'}} </td>
                                </ng-container>
                
                                <!-- <ng-container matColumnDef="validitydate">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'NOTICEBOARD.NBEnDate' | translate}}</th>
                                    <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}" mat-cell
                                        *matCellDef="let row"> {{row.validitydate | date: 'dd/MM/yyyy'}} </td>
                                </ng-container> -->
                
                                <ng-container matColumnDef="menu_tab_linkurl">
                                    <th mat-header-cell *matHeaderCellDef mat-sort-header
                                        [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                        {{'NOTICEBOARD.NBVDownloads' | translate}}</th>
                                    <td mat-cell *matCellDef="let row">
                                        <a style="cursor: pointer;" target="_blank" href="{{rootUrl + row.menu_tab_linkurl}}">
                                             <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                                        </a>
                                    </td>
                                </ng-container>
                
                
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">{{'NOTICEBOARD.NBVDownloads' | translate}} "{{input.value}}"
                                    </td>
                                </tr>
                            </table>
                
                            <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                        </div>
                
                    </mat-card-content>
                
                </mat-card>
            </div>
        </div>
        
    </div>
</div>