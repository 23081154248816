import { environment } from 'src/environments/environment';
import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from '../services/common.service';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-recruitmentnotice',
  templateUrl: './recruitmentnotice.component.html',
  styleUrls: ['./recruitmentnotice.component.scss']
})
export class RecruitmentnoticeComponent implements OnInit {


  [x: string]: any;
  rootUrl = environment.rootUrl;
  displayedColumns: string[] = ['sn', 'linkname', 'linkurl', 'issuedate', 'validitydate'];
  dataSource: MatTableDataSource<any>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  public dept_id: any;


  constructor(private http: HttpClient, private dp: DatePipe,
    public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {

    this.dept_id = 1;
    this.getimpinfo();

  }

  getimpinfo() {
    console.log(this.dept_id);
    let index = 0;
    this.commonservice.paramFunction('allimpinformation', this.dept_id).subscribe(res => {
      this.data = res;
      console.log(res);
      this.data.forEach(e => {
        this.data[index].sn = index + 1;
        index++;
      });
      this.dataSource = new MatTableDataSource(this.data);
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
    );

  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }


  hitAnchor1(e) {
    console.log("Events", e);
    // alert("You are being redirected to an external website.");
  }


}
