<div class="row">
    <div class="col-md-6">
        <mat-card class="mat-card mat-focus-indicator oh text-center little-profile"><img mat-card-image="" src="" alt="" class="mat-card-image">
            <mat-card-content class="mat-card-content">
                <div class="pro-img">
                    <img src="assets/images/users/4.png" width="80" alt="user" class="img-circle">
                    <!-- <div class="profileImage img-circle">Y</div> -->
                </div>

                <h2 class="m-b-0">{{currentUser.username | uppercase}}</h2>
                <h4 class="m-t-0">deptname_en : {{currentUser.deptname_en}}</h4>


            </mat-card-content>
        </mat-card>
    </div>
    <div class="col-md-6">
        <mat-card>
            <mat-card-content class="mat-card-content ng-star-inserted">
                <mat-card-title class="mat-card-title">Change Password</mat-card-title>
                <form [formGroup]="changePassworform" (ngSubmit)="change()">
                    <div class="form-group">
                        <label for="login-email">User Id: </label>
                        <input type="text" readonly class="form-control" id="login-email" placeholder="Enter user_id" formControlName="user_id">
                        <div *ngIf="isValidInput('user_id')">
                            <p class="text-danger" *ngIf="changePassworform.controls['user_id'].errors.required">
                                user_id is <strong>required</strong>.
                            </p>
                        </div>
                    </div>

                    <div class="form-group">
                        <label for="login-pwd">Current Password:</label>
                        <input type="password" class="form-control" id="login-pwd" placeholder="Enter Current password" formControlName="current_password">
                        <div *ngIf="isValidInput('current_password')">
                            <p class="text-danger" *ngIf="changePassworform.controls['current_password'].errors.required">
                                Current Password is <strong>required</strong>.
                            </p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="login-pwd">New Password:</label>
                        <input type="password" class="form-control" id="login-pwd" placeholder="Enter New password" formControlName="new_password">
                        <div *ngIf="isValidInput('new_password')">
                            <p class="text-danger" *ngIf="changePassworform.controls['new_password'].errors.required">
                                New Password is <strong>required</strong>.
                            </p>
                        </div>
                    </div>
                    <div class="form-group">
                        <label for="login-pwd">Confirm Password:</label>
                        <input type="password" class="form-control" id="login-pwd" placeholder="Enter Confirm password" formControlName="re_password">
                        <div *ngIf="isValidInput('re_password')">
                            <p class="text-danger" *ngIf="changePassworform.controls['re_password'].errors.required">
                                नया पासवर्ड पुनः लिखना अनिवार्य है</p>
                            <p class="text-danger" *ngIf="changePassworform.controls['re_password'].errors.confirmedValidator">
                                नया पासवर्ड एवं पुनः लिखें सामान लिखें |
                            </p>
                        </div>
                    </div>
                    <div class="text-center py-4">
                        <button type="submit" [disabled]="!changePassworform.valid" class="btn btn-primary b-btn">Change</button>
                    </div>

                </form>
            </mat-card-content>
        </mat-card>

    </div>
</div>