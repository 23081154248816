<div class="my-3" id="b-homedb">
  <div class="container-fluid">

      <div *ngIf="cms.lang.value == 'English'" id="content">
        <div class="columns-1" id="main-content" role="main">
          <div class="portlet-layout">
            <div class="portlet-column portlet-column-only" id="column-1">
              <div
                class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1"
              >
                <div
                  id="p_p_id_56_INSTANCE_9SJnB2u1LxLb_"
                  class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                >
                  <div class="portlet-body">
                    <section class="portlet" id="portlet_56_INSTANCE_9SJnB2u1LxLb">
                      <header
                        class="portlet-topper"
                        [ngStyle]="{
                          'background-color': cms.colorfront.value,
                          color: cms.colorcode.value,
                          'font-size': cms.fontsize.value + 'px'
                        }"
                      >
                        <h2 class="portlet-title">
                          <span
                            tabindex="0"
                            class="portlet-title-text"
                            [ngStyle]="{
                              color: cms.colorcode.value,
                              'font-size': cms.fontsize.value + 'px'
                            }"
                            >Screen Reader Access</span
                          >
                        </h2>
                      </header>
                      <div class="portlet-content">
                        <div class="portlet-content-container" style="">
                          <div class="portlet-body">
                            <div
                              class="journal-content-article"
                              id="article_10153_10179_87050_2.3"
                            >
                              <div style="margin-left: 5px; margin-right: 5px">
                                <div class="content-html">
                                  <hr size="1" />
                                  <p
                                    style="text-align: justify"
                                    [ngStyle]="{
                                      color: cms.colorfront.value,
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }"
                                  >
                                    The Chhattisgarh Rajya Niti Aayog, Chhattisgarh, portal complies with World
                                    Wide Web Consortium (W3C) Web Content
                                    Accessibility Guidelines (WCAG) 2.0 level AA. This
                                    will enable people with visual impairments access
                                    the website using assistive technologies, such as
                                    screen readers. The information of the website is
                                    accessible with different screen readers, such as
                                    JAWS.
                                  </p>
                                  <p
                                    style="text-align: justify"
                                    [ngStyle]="{
                                      color: cms.colorfront.value,
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }"
                                  >
                                    Following table lists the information about
                                    different screen readers:
                                  </p>
                                  <h2
                                    [ngStyle]="{
                                      color: cms.colorfront.value,
                                      'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                    }"
                                  >
                                    Information related to the various screen readers
                                  </h2>

                                  <div class="">
                                    <table
                                      class="table table-bordered"
                                      style="width: 100%"
                                      title="Screen Reader Access"
                                    >
                                      <thead class="thead-dark">
                                        <tr>
                                          <th
                                            [ngStyle]="{
                                              'background-color':
                                                cms.colorfront.value,
                                              color: cms.colorcode.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Screen Reader
                                          </th>
                                          <th
                                            [ngStyle]="{
                                              'background-color':
                                                cms.colorfront.value,
                                              color: cms.colorcode.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Website
                                          </th>
                                          <th
                                            [ngStyle]="{
                                              'background-color':
                                                cms.colorfront.value,
                                              color: cms.colorcode.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Free / Commercial
                                          </th>
                                        </tr>
                                      </thead>

                                      <tbody class="">
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Non Visual Desktop Access (NVDA)
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.nvda-project.org/"
                                              target="_blank"
                                              title="External website that opens in a new window "
                                              >http://www.nvda-project.org/
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Free
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            System Access To Go
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.satogo.com/"
                                              target="_blank"
                                              title="External website that opens in a new window "
                                              >http://www.satogo.com/
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Free
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Thunder
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.screenreader.net/index.php?pageid=2"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://www.screenreader.net/index.php?pageid=2
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Free
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            WebAnywhere
                                          </td>
                                          <td>
                                            <a
                                              href="http://webanywhere.cs.washington.edu/wa.php"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://webanywhere.cs.washington.edu/wa.php
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Free
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Hal
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.yourdolphin.co.uk/productdetail.asp?id=5"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://www.yourdolphin.co.uk/productdetail.asp?id=5
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Commercial
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            JAWS
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.freedomscientific.com/jaws-hq.asp"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://www.freedomscientific.com/jaws-hq.asp
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Commercial
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Supernova
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.yourdolphin.co.uk/productdetail.asp?id=1"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://www.yourdolphin.co.uk/productdetail.asp?id=1
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Commercial
                                          </td>
                                        </tr>
                                        <tr>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Window-Eyes
                                          </td>
                                          <td>
                                            <a
                                              href="http://www.gwmicro.com/Window-Eyes/"
                                              target="_blank"
                                              title="External website that opens in a new window"
                                              >http://www.gwmicro.com/Window-Eyes/
                                              <span
                                                class="read"
                                                [ngStyle]="{
                                                  color: cms.colorfront.value,
                                                  'font-size':
                                                    cms.fontsize.value + 'px'
                                                }"
                                                >External website that opens in a new
                                                window</span
                                              ></a
                                            >
                                          </td>
                                          <td
                                            [ngStyle]="{
                                              color: cms.colorfront.value,
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                          >
                                            Commercial
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="entry-links"></div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
      </div>

      <div *ngIf="cms.lang.value == 'Hindi'" id="content" >
        <div class="columns-1" id="main-content" role="main" >
          <div class="portlet-layout" >
            <div
              class="portlet-column portlet-column-only"
              id="column-1"
              
            >
              <div
                class="portlet-dropzone portlet-column-content portlet-column-content-only"
                id="layout-column_column-1"
                
              >
                <div
                  id="p_p_id_56_INSTANCE_9SJnB2u1LxLb_"
                  class="portlet-boundary portlet-boundary_56_ portlet-static portlet-static-end portlet-journal-content"
                  
                >
                  <span id="p_56_INSTANCE_9SJnB2u1LxLb"></span>
                  <div class="portlet-body" >
                    <section class="portlet" id="portlet_56_INSTANCE_9SJnB2u1LxLb">
                      <header class="portlet-topper" [ngStyle]="{
                        'background-color': cms.colorfront.value,
                        color: cms.colorcode.value,
                        'font-size': cms.fontsize.value + 'px'
                      }">
                        <h2 class="portlet-title">
                          <span tabindex="0" class="portlet-title-text" [ngStyle]="{
                            'color': cms.colorcode.value,                     
                            'font-size': cms.fontsize.value + 'px'
                          }"
                            >स्क्रीन रीडर एक्सेस</span
                          >
                        </h2>
                        <ul
                          class="portlet-topper-toolbar"
                          id="portlet-topper-toolbar_56_INSTANCE_9SJnB2u1LxLb"
                        ></ul>
                      </header>
                      <div class="portlet-content" >
                        <div
                          class="portlet-content-container"
                          
                        >
                          <div class="portlet-body" >
                            <div
                              class="journal-content-article"
                              id="article_10153_10179_87050_2.3"
                              
                            >
                              <div
                                style="
                                  margin-left: 5px;
                                  margin-right: 5px;
                                  font-size: 10px;
                                "
                              >
                                <div class="content-html" >
                                  <hr size="1" />
                                  <p style="text-align: justify; font-size: 10px" [ngStyle]="{
                                    'color': cms.colorfront.value,                     
                                    'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                  }">
                                    छत्तीसगढ़ राज्य नीति आयोग, छत्तीसगढ़ की वेबसाइट में वर्ल्‍ड वाइड
                                    वेब संघ (डब्‍ल्‍यू3सी) वेब विषयवस्‍तु अभिगम्‍यता
                                    मार्गदर्शी सिद्धांत (डब्‍ल्‍यूसीएजी) 2.0 स्‍तर एए
                                    का पालन किया जाता है। इससे दृष्टि हीनता से
                                    प्रभावित व्‍यक्ति सहायक प्रौद्योगिकियों जैसे
                                    स्‍क्रीन रीडर का इस्‍तेमाल करते हुए वेबसाइट देखने
                                    में सक्षम होंगे। वेबसाइट की सूचना अलग अलग स्‍क्रीन
                                    रीडरों जैसे जेएडब्‍ल्‍यूएस के साथ देखी जा सकती
                                    है।.
                                  </p>
                                  <p style="text-align: justify; " [ngStyle]="{
                                    'color': cms.colorfront.value,                     
                                    'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                  }">
                                    निम्‍नलिखित तालिका में विभिन्‍न स्‍क्रीन रीडरों के
                                    बारे में जानकारी दी गई है :
                                  </p>
                                  <h2 [ngStyle]="{
                                    'color': cms.colorfront.value,                     
                                    'font-size': cms.fontsize.value + 'px','color': cms.colorcodeblack.value
                                  }">विभिन्न स्क्रीन रीडरों के बारे में जानकारी</h2>
                                  <br />
                                  <table  class="table table-bordered"
                                  style="width: 100%"
                                  title="Screen Reader Access" >
                                  <thead>
                                    <tr>
                                      <th [ngStyle]="{
                                        'background-color': cms.colorfront.value,
                                        'color': cms.colorcode.value,                     
                                        'font-size': cms.fontsize.value + 'px'
                                      }">स्क्रीनन रीडर</th>
                                      <th [ngStyle]="{
                                        'background-color': cms.colorfront.value,
                                        'color': cms.colorcode.value,                     
                                        'font-size': cms.fontsize.value + 'px'
                                      }">वेबसाइट</th>
                                      <th [ngStyle]="{
                                        'background-color': cms.colorfront.value,
                                        'color': cms.colorcode.value,                     
                                        'font-size': cms.fontsize.value + 'px'
                                      }">निशुल्के / वाणिज्यिक</th>
                                    </tr>
                                  </thead>
                                    <tbody>
                                      
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">
                                          नॉन विजुअल डेस्क(टॉप एक्सेस (एनवीडीए))
                                        </td>
                                        <td  >
                                          <a  
                                            href="http://www.nvda-project.org/"
                                            target="_blank"
                                            title="External website that opens in a new window "
                                            >http://www.nvda-project.org/
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">निशुल्क</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">
                                          सिस्टिम एक्सेस टू गो
                                        </td>
                                        <td >
                                          <a  
                                            href="http://www.satogo.com/"
                                            target="_blank"
                                            title="External website that opens in a new window "
                                            >http://www.satogo.com/
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">निशुल्क</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">थंडर</td>
                                        <td >
                                          <a  
                                            href="http://www.screenreader.net/index.php?pageid=2"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://www.screenreader.net/index.php?pageid=2
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">निशुल्क</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">वेब एनीवेयर</td>
                                        <td >
                                          <a  
                                            href="http://webanywhere.cs.washington.edu/wa.php"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://webanywhere.cs.washington.edu/wa.php
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">निशुल्क</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">एचएएल</td>
                                        <td >
                                          <a  
                                            href="http://www.yourdolphin.co.uk/productdetail.asp?id=5"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://www.yourdolphin.co.uk/productdetail.asp?id=5
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">वाणिज्यिक</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">
                                          जेएडब्यू यर्एस
                                        </td>
                                        <td >
                                          <a  
                                            href="http://www.freedomscientific.com/jaws-hq.asp"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://www.freedomscientific.com/jaws-hq.asp
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">वाणिज्यिक</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">सुपरनोवा</td>
                                        <td >
                                          <a  
                                            href="http://www.yourdolphin.co.uk/productdetail.asp?id=1"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://www.yourdolphin.co.uk/productdetail.asp?id=1
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">वाणिज्यिक</td>
                                      </tr>
                                      <tr>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">विंडो आइज़</td>
                                        <td >
                                          <a  
                                            href="http://www.gwmicro.com/Window-Eyes/"
                                            target="_blank"
                                            title="External website that opens in a new window"
                                            >http://www.gwmicro.com/Window-Eyes/
                                            <span class="read"  [ngStyle]="{
                                              'color': cms.colorfront.value,                     
                                              'font-size': cms.fontsize.value + 'px'
                                            }"
                                              >External website that opens in a new
                                              window</span
                                            ></a
                                          >
                                        </td>
                                        <td  [ngStyle]="{
                                          'color': cms.colorfront.value,                     
                                          'font-size': cms.fontsize.value + 'px'
                                        }">वाणिज्यिक</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                            <div class="entry-links" ></div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <form action="#" id="hrefFm" method="post" name="hrefFm"><span></span></form>
      </div>

  </div>
</div>
