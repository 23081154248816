<!-- <div class="row">
    <div class="col-md-12">
        <div class="text-center">
            <div class="one">
                <h1 [ngStyle]="{'color':commonservice.colorfrontblack.value}"><i class="fa fa-building-o"
                        aria-hidden="true"></i> {{'SCHEMES.Title' | translate}} </h1>
            </div>
        </div>
    </div>
</div> -->

<!-- ---------------------------------- -->

<!-- <div class="my-5" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6">
                <h3><strong [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}">{{'SCHEMES.button' | translate}}</strong><br>
                   <small class="text-muted">Search for any number between 1 - 6</small> 
                </h3>
            </div>
            <div class="col-lg-6">
                <input type="text" #keyword (keyup)="search(keyword.value)" class="form-control"
                    aria-describedby="filterIt" placeholder="Search">
            </div>
        </div>

        <div id="cards_landscape_wrap-2">
            <div class="row">

                <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3" *ngFor="let t of data">
                    <div class="card-flyer">
                        <div class="text-box">
                            <div class="image-box">
                                <img src="{{rootUrl + t.img_link}}" alt="" />
                            </div>
                            <div class="text-container">
                                <h6 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" *ngIf="commonservice.lang.value=='English'">
                                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" routerLink='/schemedetails' >{{t.scheme_name}}</a></h6>
                                <h6 [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" *ngIf="commonservice.lang.value=='Hindi'">
                                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" routerLink='/schemedetails'>
                                        {{t.scheme_namehn}}  </a>
                                </h6>
                            </div>
                        </div>
                    </div>
                </div>
                &nbsp;
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6">
                <strong [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"><a 
                    [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}" 
                        routerLink='/schemedetails'> {{'SCHEMES.MoreSchemes' | translate}}
                    </a></strong>
            </div>
            <div class="col-lg-6">

            </div>
        </div>
    </div>
</div> -->

<!-- Topic Cards -->
<!-- <div id="cards_landscape_wrap-2">
    <div class="container-fluid">
        <div class="row">


            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <a href="">
                    <div class="card-flyer">
                        <div class="text-box">
                            <div class="image-box">
                                <img src="assets/images/schemes/baadi.jpg" alt="" />
                            </div>
                            <div class="text-container">
                                <h6>बाड़ी-योजना</h6>
                                <p>परिवार के दैनिक उपयोग हेतु कुपोषणता को दूर करने के लिए एवं अतिरिक्त आमदनी के स्रोत का सृजन कर, ग्रामीण अर्थव्यवस्था के सुदृढ़ीकरण सुनिश्चित करने के उद्देश्य से निवास स्थान के समीप उपलब्ध भूमि पर फल, सब्जी एवं पुष्प की खेती
                                    कृषकों द्वारा कराया जाना ही सुराजी गांव योजना अंतर्गत बाड़ी की परिकल्पना का मुख्य आधार हैं।</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <a href="">
                    <div class="card-flyer">
                        <div class="text-box">
                            <div class="image-box">
                                <img src="assets/images/schemes/gr.jpg" alt="" />
                            </div>

                            <div class="text-container">
                                <h6>गरुवा-योजना</h6>
                                <p>प्रदेश सरकार की महत्वकांक्षी सुराजी गांव योजना के अन्तर्गत नरवा, गरूवा, घुरवा एवं बाड़ी योजना का क्रियान्वयन विभिन्न विभागों जैसे - पंचायत एवं ग्रामीण विकास, कृषि, पशुधन विकास, उद्यानिकी, ऊर्जा, लोक स्वास्थ्य यांत्रिकी आदि
                                    के समन्वय से किया जा रहा है।
                                </p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
            <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3">
                <a href="">
                    <div class="card-flyer">
                        <div class="text-box">
                            <div class="image-box">
                                <img src="assets/images/schemes/gh.jpg" alt="" />
                            </div>
                            <div class="text-container">
                                <h6>घुरुवा-योजना</h6>
                                <p>घुरूवा कार्यक्रम का मुख्य उद्देश्य है कि ग्रामों में उपलब्ध पशुधन से प्राप्त गोबर एवं फसल अवशेष से वैज्ञानिक विधि से उच्च गुणवत्तायुक्त जैविक खाद उत्पादन कर कृषि में जैविक खाद के उपयोग को प्रोत्साहित कर काश्त लागत में कमी
                                    लाना एवं मृदा स्वास्थ्य एवं फसल उत्पादकता में वृद्धि करना है।</p>
                            </div>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div> -->

 <div class="my-5" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
               

                <div class="col-md-12">
                                    <div class="text-white">
                                        <div class="card-header text-center" [ngStyle]="{'color': '#000000', 'font-size': cms.fontsize.value +'px'}">                           
                                            {{'ABOUTSDG.AboutSdg' |
                                            translate}}
                                        </div>
                                        <div class="card-body">
                                            <div class="row "> 
                                                <div class="col-md-12">
                                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': '#000000'}" class="text-justify">{{'ABOUTSDG.Details1'|
                                                        translate}}</p>
                                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': '#000000'}" class="text-justify">{{'ABOUTSDG.Details2'|
                                                            translate}}</p>
                                                    <a [ngStyle]="{'color': cms.colorfront.value,'font-size': cms.fontsize.value +'px'}"
                                                    href="https://sdgspc.cg.gov.in/#/home" target="_blank">
                                                        {{'HOME.ViewMore' | translate}}</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
        </div>
    </div>
 </div>