<div class="my-3" id="b-homedb">
    <form [formGroup]="sdgnoticeboard">
        <div class="container-fluid">
            <div class="row">            
                <div class="col-lg-12">     
                    <div class="text-white">
                        <div class="card-header text-center" [ngStyle]="{'color': '#000000', 'font-size': cms.fontsize.value +'px'}">
                            <img src="../../assets/images/logo/sdg1.png" alt="" style="height: 25px;width: 25px;">  {{'ABOUTSDG.AboutSdg' |
                            translate}}
                        </div>
                        <div class="card-body">
                            <div class="row ">
                                <div class="col-md-12">
                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}" class="text-justify">
                                        {{'ABOUTSDG.Details1'|
                                        translate}}</p>
                                    <p [ngStyle]="{'font-size': cms.fontsize.value +'px','color': cms.colorcodeblack.value}" class="text-justify">
                                        {{'ABOUTSDG.Details2'|
                                        translate}}</p>
                                    <a [ngStyle]="{'color': cms.colorcodeblack.value,'font-size': cms.fontsize.value +'px'}"
                                        href="https://sdgspc.cg.gov.in/#/home" target="_blank">
                                        {{'HOME.ViewMore' | translate}}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <mat-card>
                        <div class="card-header text-center"
                            [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                            <img src="../../assets/images/logo/sdg.png" alt="" style="height: 25px;width: 25px;"> {{'REPORT.Reporttile' |
                            translate}}
                        </div>
                        <!-- [(value)]="selected" -->
                        <mat-card-content>
                            <div class="row">
                                <div class="col-md-8">
                                    <mat-form-field appearance="outline" class="col-md-12 col-sm-12 col-lg-12">
                                        <mat-label>{{'NOTICEBOARD.Type' | translate}}</mat-label> 
                                        <mat-select (selectionChange)="typeChange($event)"  formControlName="document_type"> 
                                            <mat-option [value]="0">
                                                सभी / All
                                            </mat-option>
                                            <mat-option *ngFor="let m of menu" [value]="m.document_type">
                                                {{m.document_type_det}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </div>                           
                            </div>

                            <mat-form-field>
                                <mat-label>खोजें/Search</mat-label>
                                <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                            </mat-form-field>

                            <div class="mat-elevation-z8" style="overflow: auto">
                                <table class="table table-bordered" mat-table [dataSource]="dataSource" matSort>
                                    <!-- ID Column -->
                                    <ng-container matColumnDef="sn">
                                        <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                            mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{'NOTICEBOARD.NBSNo' | translate}} </th>
                                        <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                            mat-cell *matCellDef="let row"> {{row.sn}} </td>
                                    </ng-container>

                                    <ng-container matColumnDef="linkname">
                                        <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                            mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{'NOTICEBOARD.NBTitle' | translate}} </th>
                                        <td [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}"
                                            mat-cell *matCellDef="let row"> {{row.document_nameen}} <br> {{row.document_namehn}}</td>
                                    </ng-container>

                                    <ng-container matColumnDef="menu_tab_linkurl">
                                        <th mat-header-cell *matHeaderCellDef mat-sort-header
                                            [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}">
                                            {{'NOTICEBOARD.NBVDownloads' | translate}}</th>
                                        <td mat-cell *matCellDef="let row">
                                            <a style="cursor: pointer;" target="_blank"
                                                href="{{rootUrl + row.document_path}}">
                                                <img src="../../assets/images/pdficon.jpg" alt="" style="height: 30px;width: 30px;">
                                            </a>
                                        </td>
                                    </ng-container>

                                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

                                    <!-- Row shown when there is no matching data. -->
                                    <tr class="mat-row" *matNoDataRow>
                                        <td class="mat-cell" colspan="4">{{'NOTICEBOARD.NBVDownloads' | translate}}
                                            "{{input.value}}"
                                        </td>
                                    </tr>
                                </table>

                                <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
                            </div>

                        </mat-card-content>

                    </mat-card>
                </div>
            </div>

        </div>
    </form>
</div>