import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { CommonService } from '../services/common.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-teamspc',
  templateUrl: './teamspc.component.html',
  styleUrls: ['./teamspc.component.scss']
})
export class TeamspcComponent implements OnInit {

  public temp: any = [];
  public data: any = [];
  public teamdesc: any = [];
  rootUrl = environment.rootUrl;

  constructor(private http: HttpClient, private dp: DatePipe, public commonservice: CommonService, public cms: CommonService) { }

  ngOnInit(): void {
    this.getSPCTeam();
  }

  getSPCTeam() {
    this.commonservice.getFunction('teamspc').subscribe(res => {
      this.data = res;
    }
  )};


  details(memeberid : any)
  {
    this.commonservice.paramFunction('aboutteammember', memeberid).subscribe(res => {
      this.teamdesc = res;
      console.log(this.teamdesc);
    });
     
  }

}
