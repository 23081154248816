
<div class="my-1" id="b-homedb">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-12">               
                <mat-card>
                    <div class="card-header text-center"
                        [ngStyle]="{'background-color': cms.colorfront.value,'color': cms.colorcode.value, 'font-size': cms.fontsize.value +'px'}">
                        <img src="../../assets/images/logo/gallery.png" alt="" style="height: 25px;width: 25px;"> {{'PHOTOGALLERY.Title' |
                        translate}}
                    </div>
                    <mat-card-content>
                        <mat-form-field>
                            <mat-label *ngIf="cms.lang.value=='English'">Search</mat-label>
                            <mat-label *ngIf="cms.lang.value=='Hindi'">खोजें</mat-label>
                            <input matInput (keyup)="applyFilter($event)" placeholder="Search" #input>
                        </mat-form-field>
                        <div class="table table-bordered text-center" style="overflow: auto">
                            <table class="" mat-table [dataSource]="dataSource" matSort>
                                <!-- ID Column -->
                                <ng-container matColumnDef="sn">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        style="text-align: center;" mat-header-cell *matHeaderCellDef mat-sort-header>
                                        {{'PHOTOGALLERY.Sno' | translate}} </th>
                                    <td mat-cell *matCellDef="let row"
                                        [ngStyle]="{'color': cms.colorfront.value, 'font-size': cms.fontsize.value +'px'}">
                                        {{row.sn}} </td>
                                </ng-container>
                                <ng-container matColumnDef="picurl">
                                    <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                        mat-header-cell *matHeaderCellDef mat-sort-header> {{'PHOTOGALLERY.Title'
                                        | translate}}
                                    </th>
                                    <td mat-cell *matCellDef="let row">
                                        <div class="card">
                                            <div class="gallery">
                                                <a target="_blank" href="{{rootUrl + row.img_link}}">
                                                    <img class="img-thumbnail" src="{{rootUrl + row.img_link}}" alt="">
                                                </a>
                                            </div>
                                        </div>
                                    </td>
                                </ng-container>
                                <ng-container matColumnDef="aboutpic">
                                    <div *ngIf="cms.lang.value=='English'">
                                        <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                            mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{'PHOTOGALLERY.Desc' | translate}}
                                        </th>
                                    </div>
                                    <div *ngIf="cms.lang.value=='Hindi'">
                                        <th [ngStyle]="{'background-color': cms.colorfront.value, 'color': cms.colorcode.value,'font-size': cms.fontsize.value +'px'}"
                                            mat-header-cell *matHeaderCellDef mat-sort-header>
                                            {{'PHOTOGALLERY.Desc' | translate}}
                                        </th>
                                    </div>
                                    <td mat-cell *matCellDef="let row">
                                        <h4 *ngIf="cms.lang.value=='English'"
                                            [ngStyle]="{ 'color': '#000000','font-size': cms.fontsize.value +'px'}">
                                            {{row.pic_name}}</h4>
                                        <h4 *ngIf="cms.lang.value=='Hindi'"
                                            [ngStyle]="{ 'color': '#000000','font-size': cms.fontsize.value +'px'}">
                                            {{row.pic_namehn}} </h4>
                                    </td>
                                </ng-container>                               
                               
                                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                                <!-- Row shown when there is no matching data. -->
                                <tr class="mat-row" *matNoDataRow>
                                    <td class="mat-cell" colspan="4">{{'DEPARTMENTS.NoRecord' | translate}}
                                        "{{input.value}}"</td>
                                </tr>
                            </table>
                            <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]"></mat-paginator>
                        </div>
                    </mat-card-content>
                </mat-card>
                
            </div>
        </div>
    </div>
</div>
            