import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';

@Component({
  selector: 'app-screenreader',
  templateUrl: './screenreader.component.html',
  styleUrls: ['./screenreader.component.scss']
})
export class ScreenreaderComponent implements OnInit {

  constructor(public cms: CommonService) { }

  ngOnInit(): void {
  }

}
